import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import PersonIcon from '@mui/icons-material/Person';

const ServiceProvider = ({ image, name, email }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    {
      image ? <MDAvatar src={image} name={name} size="sm" /> : <PersonIcon fontSize="large" />
    }
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">{name}</MDTypography>
      <MDTypography variant="caption">{email}</MDTypography>
    </MDBox>
  </MDBox>
);


export default function data(rowData) {
  return {
    columns: [
      { Header: "service Provider", accessor: "serviceProvider", width: "20%", align: "left" },
      { Header: "id", accessor: "displayId"},
      { Header: "phone", accessor: "phone", align: "center" },
      { Header: "type", accessor: "type", align: "center" },
      { Header: "category", accessor: "category", align: "center" },
      //{ Header: "business Type", accessor: "businessType", align: "center" },
      { Header: "rating", accessor: "rating", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
    ],

    rows: rowData.map(({ id, name, profileURL, phone, email, verified, rating, category, businessType, providerType }) => {
      const status = verified ? "verified" : "not verified";
      const action = verified ? "deactivate" : "verify";
      const type = providerType === 'organization' ? 'Organization' : 'Service Provider';
      return {
        id: `${providerType && providerType.toLowerCase()}/${id}`,
        displayId: <MDTypography component="p" variant="caption" color="text" fontWeight="medium"> {`${providerType && providerType.toLowerCase()}/${id}`} </MDTypography>,
        serviceProvider: <ServiceProvider image={profileURL} name={name} email={email} />,
        phone: <MDTypography component="p" variant="caption" color="text" fontWeight="medium"> {phone} </MDTypography>,
        type: <MDTypography component="p" variant="caption" color="text" fontWeight="medium"> {type} </MDTypography>,
        category: <MDTypography component="p" variant="caption" color="text" fontWeight="medium"> {category || '-'} </MDTypography>,
        //businessType: <MDTypography component="p" variant="caption" color="text" fontWeight="medium"> {businessType === -1 ? '-' : businessType} </MDTypography>,
        status: <MDBox ml={-1}><MDBadge badgeContent={status} color={status === 'verified' ? "success" : "warning"} variant="gradient" size="sm" /></MDBox>,
        rating: <MDTypography component="p" variant="caption" color="text" fontWeight="medium">{rating || '-'}</MDTypography>,
      };
    })
  };
}
