import {call, takeLatest} from 'redux-saga/effects';
import { getQueriesAPI } from './queries.apis';
import { GET_QUERIES } from './queries.constants';

function* getQueries({payload, callback}) {
    try {
        const res = yield call(getQueriesAPI, payload)
        callback(res, null)
    } catch(err) {
        callback(null, err)
    }
}

export default function* queriesSagas() {
    yield takeLatest(GET_QUERIES, getQueries)
}