import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export default function Spinner({active}) {
    const styles = {
        display: 'flex',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };

    return active ?
        <Box sx={styles}>
            <CircularProgress sx={{color: '#1A73E8'}} />
        </Box > : null
}