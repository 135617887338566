import axios from "axios";

export const getSubCategoriesAPI = (categoryId) => axios.get(`{server}/admin/categories/${categoryId}/subcategories`)
    .then(res => res.data.success)

export const addNewSubCategoryAPI = ({categoryId, payload}) => axios.post(`{server}/admin/categories/${categoryId}/subcategories`, payload)
    .then((res) => res.data.success)

export const editSubCategoryAPI = ({id, categoryId, payload}) => axios.patch(`{server}/admin/categories/${categoryId}/subcategories/${id}`, payload)
    .then((res) => res.data.success)

export const deleteSubCategoryAPI = ({categoryId, id}) => axios.delete(`{server}/admin/categories/${categoryId}/subcategories/${id}`)
    .then((res) => res.data.success)