import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import DashboardLayout from "container-components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "container-components/Navbars/DashboardNavbar";
import UserInfo from "../components/userInfo";
import MDSnackbar from "components/MDSnackbar";
import MDDialog from "components/MDDialog";
import OrderInfo from "../components/orderInfo";
import { getOrder, getCustomer, getProvider, issueRefund } from "./order.actions";
import { getCustomerData, getOrderData, getProviderData } from "./order";
import MDBox from "components/MDBox";

const OrderView = () => {
    const dispatch = useDispatch();
    const { orderId } = useParams();
    const [orderData, setOrderData] = useState({});
    const [rerender, setRerender] = useState(false);
    const [customerData, setCustomerData] = useState({});
    const [providerData, setProviderData] = useState({});
    const [toDelete, setToDelete] = useState(null);
    const [snackbar, setSnackbar] = useState({
        color: "error",
        title: "Refund Error",
        icon: "warning",
        content: "Unable to issue refund",
        open: false,
        dateTime: "now",
        onClose: null,
        close: null,
    });

    const setErrorSnack = (title, content) => {
        setSnackbar({
            title,
            content,
            close: () => setSnackbar({ open: false }),
            onClose: () => setSnackbar({ open: false }),
            color: "error",
            icon: "warning",
            open: true
        });
    }

    useEffect(() => {
        dispatch(getOrder(orderId, (success, err) => {
            if(err) {
                console.log(err);
            } else {
                const {providerId, providerType, customerId} = success;
                setOrderData({...getOrderData(success), providerId, providerType, customerId })
            }
        }))
    }, [rerender])

    useEffect(() => {
        if(orderData.id) {
            const {providerId, providerType, customerId} = orderData; 
            if (customerId) {
                dispatch(getCustomer(customerId, (success, err) => {
                    if(err) {
                        console.log(err);
                    } else {
                        setCustomerData(getCustomerData(success))
                    }
                }))
            }
            if (providerId && providerType) {
                dispatch(getProvider({providerId, providerType: providerType.toLowerCase()}, (success, err) => {
                    if(err) {
                        console.log(err);
                    } else {
                        setProviderData(getProviderData(success))
                    }
                }))
            }
        }
    }, [orderData])

    const handleIssueRefund = () => {
        dispatch(issueRefund({orderId}, (_, err) => {
            if(err) {
                setErrorSnack('Refund error', "Unable to refund selected order");
            } else {
                setSnackbar({
                    title: "Refund Issued",
                    content: "The order has been refunded",
                    close: () => setSnackbar({open: false}),
                    onClose: () => setSnackbar({open: false}),
                    color: 'info',
                    icon: 'info',
                    open: true,
                })
                setRerender(true);
            }
            setToDelete(null);
        }))
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDSnackbar {...snackbar}/>
            <MDDialog
                text={`Are you sure you want to issue refund for this order ?`}
                title={'Issue Refund'}
                onConfirm={handleIssueRefund}
                confirmationText={'Refund'}
                open={!!toDelete}
                onClose={() => setToDelete(null)}
            />
            <MDBox display="flex" justifyContent="space-evenly" alignItems="center" sx={{flexWrap:"wrap"}} >
                <MDBox width="45%">
                    <UserInfo {...customerData} isCustomer/>
                </MDBox>
                <MDBox width="45%">
                    <UserInfo {...providerData} />
                </MDBox>
                <MDBox width="95%">
                    <OrderInfo {...orderData} handleRefundClick={() => setToDelete(orderId)} />
                </MDBox>
            </MDBox>
        </DashboardLayout>
    )
}

export default OrderView;