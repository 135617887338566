import CustomAccordion from "components/Accordion";
import MDBox from "components/MDBox";
import DashboardLayout from "container-components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "container-components/Navbars/DashboardNavbar";
import { CustomTable } from "container-components/Tables/DataTable";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { formatProvider, orderData } from "./provider";
import providerData from 'layouts/tables/serviceproviders/serviceproviders';
import { getProvider, getProviderOrders, getOrganizationMembers, verifyProvider, activateProvider, deactivateProvider } from "./provider.actions";
import ProviderHeader from "./provider.header";
import ProviderProfileCard from './provider.profile';
import MDDialog from "components/MDDialog";
import MDSnackbar from "components/MDSnackbar";

export default function ProviderProfile() {
    const dispatch = useDispatch();
    const { providerType, providerId } = useParams();
    const [provider, setProvider] = useState({});
    const [ordersData, setOrdersData] = useState({ columns: [], rows: [], totalPages: 0, totalCount: 0 });
    const [membersData, setMembersData] = useState({ columns: [], rows: [], totalPages: 0, totalCount: 0 });
    const [snackbar, setSnackbar] = useState({
        color: "",
        title: "",
        icon: "",
        content: "",
        open: false,
        dateTime: "now",
        onClose: null,
        close: null,
    });
    const [dialog, setDialog] = useState({
        text: '',
        title: '',
        confirmationText: '',
        open: false,
        onConfirm: null,
        onClose: null,
    });

    const setErrorSnack = (title, content) => {
        setSnackbar({
            title,
            content,
            close: () => setSnackbar({ ...snackbar, open: false }),
            onClose: () => setSnackbar({ ...snackbar, open: false }),
            color: "error",
            icon: "warning",
            open: true
        });
    }

    const handleActivate = () => {
        setDialog({
            text: `Are you sure you want to activate this ${provider.providerType} ?`,
            title: 'Activate',
            confirmationText: 'Activate',
            open: true,
            onClose: () => setDialog({ ...dialog, open: false }),
            onConfirm: () => {
                dispatch(activateProvider({ providerType, providerId }, (success, err) => {
                    if (err) {
                        setErrorSnack('Unknown error', 'Unable to activate provider');
                    } else {
                        setProvider({ ...provider, deactivated: false });
                    }
                    setDialog({ ...dialog, open: false })
                }))
            },
        });
    }

    const handleDeactivate = () => {
        setDialog({
            text: `Are you sure you want to deactivate this ${provider.providerType} ?`,
            title: 'Deactivate',
            confirmationText: 'Deactivate',
            open: true,
            onClose: () => setDialog({ ...dialog, open: false }),
            onConfirm: () => {
                dispatch(deactivateProvider({ providerType, providerId }, (success, err) => {
                    if (err) {
                        setErrorSnack('Unknown error', 'Unable to deactivate provider');
                    } else {
                        setProvider({ ...provider, deactivated: true });
                    }
                    setDialog({ ...dialog, open: false })
                }))
            },
        });
    }

    const handleVerify = (verify) => {
        const verifyType = verify ? 'Verify' : 'Decline';
        setDialog({
            text: `Are you sure you want to ${verifyType.toLowerCase()} this ${provider.providerType} ?`,
            title: verifyType,
            confirmationText: verifyType,
            open: true,
            onClose: () => setDialog({ ...dialog, open: false }),
            onConfirm: () => {
                dispatch(verifyProvider({ providerType, providerId, verify }, (success, err) => {
                    if (err) {
                        setErrorSnack('Unknown error', `Unable to ${verifyType.toLowerCase()} provider`);
                    } else {
                        setProvider({ ...provider, verfied: true });
                    }
                    setDialog({ ...dialog, open: false })
                }))
            },
        });
    }

    const getData = ({ pageSize, pageIndex, search }) => {
        dispatch(getProviderOrders({ providerId, page: pageIndex + 1, limit: pageSize, providerType, search }, (success, err) => {
            if (err) {
                setSnackbar({ ...snackbar, color: "error", title: "Unknown Error", icon: "warning", content: "Error in fetching orders", open: true });
            } else {
                setOrdersData({ ...orderData(success.items || []), totalCount: success.totalCount, totalPages: Math.ceil(success.totalCount / pageSize) });
            }
        }))
    }

    const getMembersData = () => {
        dispatch(getOrganizationMembers(providerId, (success, err) => {
            if (err) {
                setSnackbar({ ...snackbar, color: "error", title: "Unknown Error", icon: "warning", content: "Error in fetching members", open: true });
            } else {
                setMembersData(providerData(success));
            }
        }))
    }

    useEffect(() => {
        dispatch(getProvider({ providerId, providerType }, (success, err) => {
            if (err) {
                setSnackbar({ ...snackbar, color: "error", title: "Unknown Error", icon: "warning", content: "Error in fetching provider", open: true });
            } else {
                setProvider(formatProvider(success, providerType));
            }
        }))
    }, [])

    const { name, image, id, phone, email, rating, deactivated, disabled, verified } = provider;
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDDialog {...dialog} />
            <MDSnackbar {...snackbar} />
            <ProviderHeader
                name={name}
                image={image}
                providerType={provider.providerType}
                buttons={{
                    activate: {
                        disabled: !deactivated || disabled,
                        handleClick: handleActivate,
                    },
                    deactivate: {
                        disabled: deactivated || disabled,
                        handleClick: handleDeactivate,
                    },
                    verify: {
                        disabled: verified,
                        handleClick: () => handleVerify(true),
                    },
                    decline: {
                        disabled: verified,
                        handleClick: () => handleVerify(false),
                    },
                }}
            >
                <MDBox mt={5} mb={3}>
                    <CustomAccordion title="Profile Information" description={'Provider Details'} expanded={true}>
                        <ProviderProfileCard
                            info={{
                                providerId: id, phone, email, rating, deactivated: `${deactivated}`, disabled: `${disabled}`, verified: `${verified}`,
                            }}
                            shadow={false}
                        />
                    </CustomAccordion>
                    <CustomAccordion title="Order Details" description={`Orders for ${name}`}>
                        <CustomTable
                            columns={[...ordersData.columns]}
                            data={[...ordersData.rows]}
                            isSorted={false}
                            showTotalEntries
                            noEndBorder
                            loading={false}
                            fetchData={getData}
                            pageCount={ordersData.totalPages}
                            totalCount={ordersData.totalCount}
                            handleRowClick={(id) => console.log(id)}
                        />
                    </CustomAccordion>
                    {
                        providerType === 'organization' ?
                            <CustomAccordion title="Members" description={`Member of the organization ${name}`}>
                                <CustomTable
                                    columns={[...membersData.columns]}
                                    data={[...membersData.rows]}
                                    isSorted={false}
                                    showTotalEntries
                                    noEndBorder
                                    disableSearch
                                    disablePagination
                                    loading={false}
                                    fetchData={getMembersData}
                                    pageCount={membersData.totalPages}
                                    totalCount={membersData.totalCount}
                                    handleRowClick={(id) => console.log(id)}
                                />
                            </CustomAccordion> : null
                    }
                </MDBox>
            </ProviderHeader>
        </DashboardLayout>
    )
}