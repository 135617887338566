import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//React
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDDialog from "components/MDDialog";

import DashboardLayout from "container-components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "container-components/Navbars/DashboardNavbar";
import { CustomTable } from "container-components/Tables/DataTable/index";

import serviceProvidersData from "./serviceproviders";
import { getServiceProviders } from "./serviceproviders.actions";

function ServiceProviderTable({ tableHeader }) {
  const [alertOptions, setAlertOptions] = useState({
    open: false,
    confirm: () => { console.log('confirm') },
    text: '',
    title: '',
    confirmText: '',
  });
  const { open, confirm, text, title, confirmText } = alertOptions;
  const [data, setData] = useState({ columns: [], rows: [], totalPages: 0, totalCount: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const categories = useSelector(state => state.app.categories);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getData = ({ pageSize, pageIndex, search, filters : {category, status}}) => {
    setLoading(true);
    const verified = status === 'all' ? null : status === 'verified' ? true : false;
    dispatch(getServiceProviders({ page: pageIndex + 1, limit: pageSize, search, category, verified }, (success, err) => {
      if (err) {
        setError(true);
      } else {
        setData({ ...serviceProvidersData(success.items, handleActionClick), totalCount: success.totalCount, totalPages: Math.ceil(success.totalCount / pageSize) });
      }
      setLoading(false);
    }))
  }

  const handleRowClick = (id) => {
    navigate(id);
  }

  const handleDeactivate = (id) => {
    setAlertOptions({ open: false })
  }

  const handleVerify = (id) => {
    setAlertOptions({ open: false })
  }

  const handleActionClick = ({ action, selected: { name, id } }) => {
    let handleConfirm = () => handleDeactivate(id);
    let alertText = `Do you want to deactivate '${name}'`;
    let alertTitle = 'Deactivate Service Provider';
    let alertConfirmText = 'Deactivate';
    if (action === 'verify') {
      handleConfirm = () => handleVerify(id);
      alertText = `Do you want to verify '${name}'`;
      alertTitle = 'Verify Service Provider';
      alertConfirmText = 'Verify';
    }
    setAlertOptions({
      open: true,
      confirm: handleConfirm,
      text: alertText,
      title: alertTitle,
      confirmText: alertConfirmText,
    });
  }

  const handleCloseSnackBar = () => {
    setError(false);
  }

  return (
    <DashboardLayout>
      <MDDialog
        open={open}
        text={text}
        title={title}
        onConfirm={confirm}
        onClose={() => setAlertOptions({ open: false })}
        confirmationText={confirmText}
      ></MDDialog>
      <MDSnackbar
        color="error"
        title="Some Error"
        icon="warning"
        dateTime="now"
        content="There was a problem in fetching service providers."
        open={error}
        onClose={handleCloseSnackBar}
        close={handleCloseSnackBar}
      />
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {tableHeader}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <CustomTable
                  columns={[...data.columns]}
                  data={[...data.rows]}
                  isSorted={false}
                  showTotalEntries
                  noEndBorder
                  loading={loading}
                  fetchData={getData}
                  pageCount={data.totalPages}
                  totalCount={data.totalCount}
                  handleRowClick={handleRowClick}
                  filterOptions={[{
                    name: 'Status',
                    options: [{label: 'All', value: 'all'}, {label: 'Verified', value: 'verified'}, {label: 'Not Verified', value: 'unverified'}],
                    type: 'radio',
                  },{
                    name: 'Categories',
                    options: ['All', ...categories],
                    type: 'list',
                  }]}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ServiceProviderTable;
