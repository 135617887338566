import { ACCEPT_REQUEST, DECLINE_REQUEST, GET_REQUEST } from "./request.constants";
import { call, takeLatest } from 'redux-saga/effects'
import {acceptRequestAPI, declineRequestAPI, getRequestAPI} from './request.apis';

function* getRequest({payload, callback}) {
    try {
        const res = yield call(getRequestAPI, payload);
        callback(res, null)
    } catch(err) {
        callback(null, err)
    }
}

function* acceptRequestSaga({payload, callback}) {
    try {
        const res  = yield call(acceptRequestAPI, payload)
        callback(res, null)
    } catch(err) {
        callback(null, err)
    }
}

function* declineRequestSaga({payload, callback}) {
    try {
        const res = yield call(declineRequestAPI, payload)
        callback(res, null)
    } catch(err) {
        callback(null, err)
    }
}

export default function* requestSagas() {
    yield takeLatest(GET_REQUEST, getRequest)
    yield takeLatest(ACCEPT_REQUEST, acceptRequestSaga)
    yield takeLatest(DECLINE_REQUEST, declineRequestSaga)
}