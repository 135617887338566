import axios from "axios";

export const getCategoriesAPI = () => axios.get('{server}/admin/categories')
    .then((res) => res.data.success)

export const addNewCategoryAPI = (payload) => axios.post(`{server}/admin/categories`, payload)
    .then((res) => res.data.success)

export const editCategoryAPI = ({id, payload}) => axios.patch(`{server}/admin/categories/${id}`, payload)
    .then((res) => res.data.success)

export const deleteCategoryAPI = (id) => axios.delete(`{server}/admin/categories/${id}`)
    .then((res) => res.data.success)

export const getSubCategoriesAPI = (id) => axios.get(`/neerbiweb/subcategories?categoryId=${id}`).then(res => res.data.success)

export const uploadImageAPI = (payload) => axios.post(`{server}/admin/bucket/images`, payload).then(res => res.data.success)

function replaceQueryParams(url, params) {
    return `${url}`
}