import {call, takeLatest} from 'redux-saga/effects';
import { getProviderOrdersAPI, getOrganizationMembersAPI, verifyProviderAPI, activateProviderAPI, deactivateProviderAPI } from './provider.apis';
import { GET_ORGANIZATION_MEMBERS, GET_PROVIDER_ORDERS, VERIFY_PROVIDER, ACTIVATE_PROVIDER, DEACTIVATE_PROVIDER } from './provider.constants';

function* getProviderOrders({payload, callback}) {
    try {
        const res = yield call(getProviderOrdersAPI, payload);
        callback(res, null)
    } catch(err) {
        callback(null, err)
    }
}

function* getOrganizationMemebers({payload, callback}) {
    try {
        const res = yield call(getOrganizationMembersAPI, payload);
        callback(res, null)
    } catch(err) {
        callback(null, err)
    }
}

function* verifyProviderSaga({payload, callback}) {
    try {
        const res = yield call(verifyProviderAPI, payload);
        callback(res, null)
    } catch(err) {
        callback(null, err)
    }
}

function* activateProviderSaga({payload, callback}) {
    try {
        const res = yield call(activateProviderAPI, payload);
        callback(res, null)
    } catch(err) {
        callback(null, err)
    }
}

function* deactivateProviderSaga({payload, callback}) {
    try {
        const res = yield call(deactivateProviderAPI, payload);
        callback(res, null)
    } catch(err) {
        callback(null, err)
    }
}

export default function* providerProfileSagas () {
    yield takeLatest(GET_PROVIDER_ORDERS, getProviderOrders)
    yield takeLatest(GET_ORGANIZATION_MEMBERS, getOrganizationMemebers)
    yield takeLatest(VERIFY_PROVIDER, verifyProviderSaga)
    yield takeLatest(ACTIVATE_PROVIDER, activateProviderSaga)
    yield takeLatest(DEACTIVATE_PROVIDER, deactivateProviderSaga)
}