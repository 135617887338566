import { all, fork } from 'redux-saga/effects'

import appSagas from 'App.sagas';
import mapSagas from 'layouts/maps/maps.sagas';
import loginSagas from '../layouts/authentication/sign-in/sign-in.sagas';
import ordersSagas from '../layouts/tables/orders/orders.sagas';
import orderSagas from '../layouts/dashboard/order/order.sagas';
import metricsSagas from 'layouts/dashboard/dashboard.sagas';
import queriesSagas from 'layouts/tables/queries/queries.sagas';
import requestSagas from 'layouts/request/request.sagas';
import requestsSagas from '../layouts/tables/requests/requests.sagas';
import customerSagas from '../layouts/tables/customers/customers.sagas';
import providerProfileSagas from 'layouts/providerProfile/provider.sagas';
import customerProfileSagas from 'layouts/customerProfile/customer.sagas';
import serviceProviderSagas from '../layouts/tables/serviceproviders/serviceproviders.sagas';
import bannerManagementSagas from 'layouts/banners/banners.sagas';
import categoryManagementSagas from 'layouts/categories/category-management.sagas';
import subCategoryManagementSagas from 'layouts/sub-categories/sub-category-management.sagas';

export default function* rootSagas() {
  yield all([
    fork(appSagas),
    fork(mapSagas),
    fork(loginSagas),
    fork(serviceProviderSagas),
    fork(providerProfileSagas),
    fork(customerSagas),
    fork(customerProfileSagas),
    fork(ordersSagas),
    fork(orderSagas),
    fork(categoryManagementSagas),
    fork(subCategoryManagementSagas),
    fork(metricsSagas),
    fork(requestsSagas),
    fork(requestSagas),
    fork(queriesSagas),
    fork(bannerManagementSagas),
  ])
}
