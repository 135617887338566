import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActions } from '@mui/material';

export default function Banner({ name, type, image, onDelete }) {
  return (
    <Card sx={{ maxWidth: 300, width: 300, margin: 2 }}>
      <CardMedia
        component="img"
        height="140"
        image={image}
        alt={type}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {name && name.toUpperCase()}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {type || <br />}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" color="error" sx={{ color: 'red' }} onClick={onDelete}>
          Delete
        </Button>
      </CardActions>
    </Card>
  );
}
