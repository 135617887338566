import { call, takeLatest } from 'redux-saga/effects'
import { ADD_NEW_BANNER, DELETE_BANNER, GET_BANNERS_LIST, UPLOAD_BANNER } from './banners.constants'
import { addNewBannerAPI, deleteBannerAPI, getBannersAPI, uploadBannerAPI } from './banners.apis'

function* getBannersListSaga({callback}) {
    try {
        const res = yield call(getBannersAPI)
        callback(res, null)
    } catch (e) {
        callback(null, e)
    }
}

function* uploadBannerSaga({payload, callback}) {
    try {
        const res = yield call(uploadBannerAPI, payload)
        callback(res, null)
    } catch (e) {
        callback(null, e)
    }
}

function* addNewBannerSaga({payload, callback}) {
    try {
        const res = yield call(addNewBannerAPI, payload);
        callback(res, null)
    } catch(err) {
        callback(null, err);
    }
}

function* deleteBannerSaga({payload, callback}) {
    try {
        const res = yield call(deleteBannerAPI, payload);
        callback(res, null);
    } catch(err) {
        callback(null, err);
    }
}

export default function* bannerManagementSagas() {
    yield takeLatest(GET_BANNERS_LIST, getBannersListSaga)
    yield takeLatest(UPLOAD_BANNER, uploadBannerSaga)
    yield takeLatest(ADD_NEW_BANNER, addNewBannerSaga)
    yield takeLatest(DELETE_BANNER, deleteBannerSaga)
}
