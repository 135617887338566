import { Card, CardContent } from "@mui/material";
import MDTypography from "components/MDTypography";

const humanTimestamp = (stringTimestamp) => {
  const timestamp = new Date(stringTimestamp);
  return `${timestamp.toLocaleTimeString()}, ${timestamp.toLocaleDateString()}`
}

export default function data(rowData) {
  return {
    columns: [
      { Header: "id", accessor: "queryId", align: "left" },
      { Header: "name", accessor: "name", align: "left" },
      { Header: "email", accessor: "email" },
      { Header: "sent at", accessor: "createdAt"},
      { Header: "message", accessor: "description", width: "70%", align: "left" },
    ],

    rows: rowData.map(({id, name, read, replied, description, email, updatedAt, createdAt}) => {
      return {
        id,
        queryId: <MDTypography component="p" variant="caption" color="text" fontWeight="medium"> {id} </MDTypography>,
        name: <MDTypography component="p" variant="caption" color="text" fontWeight="medium"> {name} </MDTypography>,
        email: <MDTypography component="p" variant="caption" color="text" fontWeight="medium"> {email} </MDTypography>,
        createdAt: <MDTypography component="p" variant="caption" color="text" fontWeight="medium"> {humanTimestamp(createdAt)} </MDTypography>,
        description: <div style={{maxWidth: '500px'}} component="p" variant="caption" color="text" fontWeight="medium"> {Array(10).fill(description).join(', ')} </div>,
      };
    })
  };
}
