import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import DashboardLayout from 'container-components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'container-components/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import Spinner from 'components/Spinner';
import MDSnackbar from "components/MDSnackbar";
import Marker from 'components/Marker';
import { formatData } from './maps';
import { getAddresses } from './maps.actions';
import { useDispatch } from 'react-redux';

const MapComponent = ({ apiKey }) => {
    const dispatch = useDispatch();
    const [zoom, setZoom] = useState(10);
    const [center, setCenter] = useState({lat: 34.0522, lng: -118.2437});
    const [places, setPlaces] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleCloseSnackBar = () => {
        setSnackOpen(false);
    }

    const getPlaces = () => {
        dispatch(getAddresses({}, (success, err) => {
            if (err) {
                setSnackOpen(true);
            } else {
                setPlaces([...formatData(success.items)])
            }
        }))
    }

    useEffect(() => {
        getPlaces();
    }, [])

    useEffect(() => {
        if(places.length) {
            setCenter({lat: places[0].latitude, lng: places[0].longitude})
            setLoading(false);
        }
    }, [places])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDSnackbar
                color="error"
                title="Unknown Error"
                icon="warning"
                dateTime="now"
                content="There was a problem in setting map data."
                open={snackOpen}
                onClose={handleCloseSnackBar}
                close={handleCloseSnackBar}
            />
            <MDBox style={{ height: Math.floor((window.innerHeight - 100) / 100) * 100 }}>
                {
                    loading ? <Spinner active={loading} /> :
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: apiKey,
                            }}
                            defaultZoom={11}
                            yesIWantToUseGoogleMapApiInternals
                            defaultCenter={[34.0522, -118.2437]}
                            zoom={zoom}
                            onChange={({ zoom }) => setZoom(zoom)}
                            center={center}
                        >
                            {
                                places.map((item, index) => (
                                    <Marker
                                        key={index}
                                        name={item.name}
                                        onClick={() => setCenter({ lat: item.latitude, lng: item.longitude })}
                                        //address={item.address}
                                        zoom={zoom}
                                        lat={item.latitude}
                                        lng={item.longitude}
                                    />
                                ))
                            }
                        </GoogleMapReact>
                }
            </MDBox>
        </DashboardLayout>
    )
}

export default MapComponent;