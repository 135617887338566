import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import PersonIcon from '@mui/icons-material/Person';

const Customer = ({ image, name, email }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    {
        image ? <MDAvatar src={image} name={name} size="sm" /> : <PersonIcon fontSize="large"/>
    }
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">{name}</MDTypography>
      <MDTypography variant="caption">{email}</MDTypography>
    </MDBox>
  </MDBox>
);

const Job = ({ title, description }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
    <MDTypography variant="caption">{description}</MDTypography>
  </MDBox>
);

export default function data(rowData, actionClickHandler) {
  return {
    columns: [
      { Header: "customer", accessor: "customer", width: "20%", align: "left" },
      { Header: "id", accessor: "displayId"},
      { Header: "phone", accessor: "phone", align: "center" },
      { Header: "rating", accessor: "rating", align: "center" },
    ],

    rows: rowData.map(({id, firstName, lastName, profileURL, phone, email, rating }) => {
      const name = `${firstName || ''} ${lastName || ''}`
      return {
        id,
        displayId: <MDTypography component="p" variant="caption" color="text" fontWeight="medium"> {id} </MDTypography>,
        customer: <Customer image={profileURL} name={name} email={email} />,
        phone: <MDTypography component="p" variant="caption" color="text" fontWeight="medium"> {phone} </MDTypography>,
        rating: <MDTypography component="p" variant="caption" color="text" fontWeight="medium">{rating}</MDTypography>,
      };
    })
  };
}
