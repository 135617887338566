import { useState, Fragment } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { sendEmail, resetPassword } from "./reset.actions";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

function Reset() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, _] = useSearchParams();
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const token = searchParams.get('token');
    const [error, setError] = useState({
        open: false,
        title: '',
        content: '',
        color: 'error', icon: 'warning',
    });

    const handleSubmit = () => {
        if (password && rePassword) {
            if (password !== rePassword) {
                setError({ ...error, open: true, color: 'error', icon: 'warning', title: 'Password Error', content: 'Passwords do not match' })
                return
            }

            dispatch(resetPassword({password, rePassword, token}, (success, err) => {
                if(err) {
                    setError({ ...error, open: true, color: 'error', icon: 'warning', title: 'Reset Password', content: 'Unable to reset password' })
                } else {
                    setError({ ...error, open: true, color: 'info', icon: 'info', title: 'Password Reset Successfully', content: 'Please login to continue' })
                    setTimeout(() => {
                        navigate('/authentication/sign-in');
                    }, 2000)
                }
            }))
        } else {
            setError({ ...error, open: true, color: 'error', icon: 'warning', title: 'Input Error', content: 'Please fill all the fields' })
        }
    }

    const handleEmail = () => {
        dispatch(sendEmail((_, err) => {
            if(err) {
                setError({ ...error, open: true, color: 'error', icon: 'warning', title: 'Unknown error', content: 'Unexpected error happened' })
            } else {
                setError({ ...error, open: true, color: 'info', icon: 'info', title: 'Email Sent', content: 'Email sent successfully' })
            }
        }));
    }

    const closeSnackBar = () => {
        setError({ ...error, open: false })
    }

    return (
        <Card >
            <MDSnackbar
                color={error.color}
                icon={error.icon}
                dateTime="now"
                title={error.title}
                content={error.content}
                open={error.open}
                onClose={closeSnackBar}
                close={closeSnackBar}
            />
            <MDBox pt={0} pb={3} px={3}>
                <MDBox component="form" role="form">
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                            Reset Password
                        </MDTypography>
                    </MDBox>
                    <MDBox mb={4}>
                    </MDBox>
                    {
                        token ?
                            <Fragment>
                                <MDBox mb={4}>
                                    <MDInput type="password" label="New Password" variant="standard" fullWidth value={password} onChange={({ target }) => setPassword(target.value)} />
                                </MDBox>
                                <MDBox mb={4}>
                                    <MDInput type="password" label="Confrim New Password" variant="standard" fullWidth value={rePassword} onChange={({ target }) => setRePassword(target.value)} />
                                </MDBox>
                                <MDBox mt={6} mb={1}>
                                    <MDButton variant="outlined" color="info" fullWidth onClick={handleSubmit}>
                                        reset password
                                    </MDButton>
                                </MDBox>
                            </Fragment> :
                            <MDBox mt={6} mb={1}>
                                <MDButton variant="outlined" color="info" fullWidth onClick={handleEmail}>
                                    Send email to registered email id
                                </MDButton>
                                {/* <MDTypography variant="caption" color="error">
                                    *An email can be sent only once an hour
                                </MDTypography> */}
                            </MDBox>
                    }
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default Reset;
