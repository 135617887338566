import React, {useState, useEffect} from 'react';
import DashboardLayout from 'container-components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'container-components/Navbars/DashboardNavbar';
import AddIcon from '@mui/icons-material/AddCircleOutlined';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import useStyles from './banners.styles'
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDDialog from 'components/MDDialog';
import Typography from "@mui/material/Typography";
import Banner from './banner';
import AddBannerDialog from './banner-dialog';
import { deleteBanner, getBannersList } from './banners.actions';

export function Banners() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [addDialog, setAddDialog] = useState(false);
    const [banners, setBanners] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [toDelete, setToDelete] = useState(null);

    useEffect(() => {
        dispatch(getBannersList((success, err) => {
            if (err) {
                console.log(err)
            } else {
                setBanners(success)
            }
        }))
    }, [rerender]);

    const handleClose = () => {
        setAddDialog(false);
    }

    const handleDelete = () => {
        dispatch(deleteBanner(toDelete.id, (success, err) => {
            if (err) {
                console.log(err);
            } else {
                setToDelete(null);
                setRerender(!rerender);
            }
        }))
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDDialog
                text={`Are you sure you want to delete this banner ?`}
                title={'Delete Banner'}
                onConfirm={handleDelete}
                confirmationText={'Delete'}
                open={!!toDelete}
                onClose={() => setToDelete(null)}
            />
            <MDBox>
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant='subtitle1' color='text.secondary'>
                        {/* You can have a maximum of 8 banners at a time. */}
                    </Typography>
                    <MDButton
                        variant="outlined"
                        startIcon={<AddIcon color="info" />}
                        onClick={() => setAddDialog(true)}
                        className={classes.addButton}
                    >
                        <Typography variant='content5' sx={{ color: '#1A73E8' }}>add new banner</Typography>
                    </MDButton>
                </MDBox>
                <MDBox className={classes.bannersContainer}>
                    {banners.map(ct => (
                        <Banner
                            key={ct.id}
                            name={ct.name}
                            type={ct.type}
                            image={ct.image}
                            onDelete={() => setToDelete(ct)}
                        />
                    ))}
                </MDBox>
                <AddBannerDialog
                    open={addDialog}
                    onClose={handleClose}
                    rerender={rerender}
                    setRerender={setRerender}
                />
            </MDBox>
        </DashboardLayout>
    )
}