import axios from "axios";

export const getBannersAPI = () => axios.get('{server}/admin/banners')
    .then((res) => res.data.success)

export const addNewBannerAPI = (payload) => axios.post(`{server}/admin/banners`, payload)
    .then((res) => res.data.success)

export const deleteBannerAPI = (id) => axios.delete(`{server}/admin/banners/${id}`)
    .then((res) => res.data.success)

export const uploadBannerAPI = (payload) => axios.post(`{server}/admin/bucket/images`, payload).then(res => res.data.success)