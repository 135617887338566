/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
import Dashboard from "layouts/dashboard";
import ServiceProvidersTable from "layouts/tables/serviceproviders";
import ProviderProfile from "layouts/providerProfile";
import CustomersTable from "layouts/tables/customers";
import CustomerProfile from "layouts/customerProfile";
import OrdersTable from "layouts/tables/orders";
import OrderView from "layouts/dashboard/order";
import CategoryManagement from "layouts/categories";
import SubCategoryManagement from "layouts/sub-categories";
import Requests from "layouts/tables/requests";
import Request from "layouts/request";
import Queries from "layouts/tables/queries";

import Icon from "@mui/material/Icon";
import GroupIcon from '@mui/icons-material/Group';
import MapComponent from "layouts/maps";
import Banners from "layouts/banners";

function getMapApiKey() {
  return process.env.REACT_APP_MAP_API_KEY
}

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },{
    type: "collapse",
    name: "Service Providers",
    key: "serviceproviders",
    icon: <GroupIcon fontSize="small" />,
    route: "/serviceproviders",
    component: <ServiceProvidersTable tableHeader="Service Providers" />,
  },{
    type: "collapse",
    key: "serviceproviders",
    route: "/serviceproviders/:providerType/:providerId",
    component: <ProviderProfile />,
    isSubRoute: true,
  },{
    type: "collapse",
    name: "Customers",
    key: "customers",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/customers",
    component: <CustomersTable tableHeader="Customers" />,
  },{
    type: "collapse",
    key: "customers",
    route: "/customers/:customerId",
    component: <CustomerProfile />,
    isSubRoute: true,
  },{
    type: "collapse",
    name: "Orders",
    key: "orders",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/orders",
    component: <OrdersTable tableHeader="Orders" />,
  },{
    type: "collapse",
    key: "orders",
    route: "/orders/:orderId",
    component: <OrderView/>,
    isSubRoute: true,
  },{
    type: "collapse",
    name: "Categories",
    key: "categories",
    route: "/categories",
    icon: <Icon fontSize="medium">add_shopping_cart</Icon>,
    component: <CategoryManagement />
  },{
    type: "collapse",
    key: "categories",
    route: "/categories/:categoryId",
    component: <SubCategoryManagement />,
    isSubRoute: true,
  },{
    type: "collapse",
    name: "Requests",
    key: "requests",
    route: "/requests",
    icon: <Icon fontSize="medium">change_circle</Icon>,
    component: <Requests tableHeader="Change Requests" />
  },{
    type: "collapse",
    key: "requests",
    route: "/requests/:requestId",
    component: <Request />,
    isSubRoute: true,
  },{
    type: 'collapse',
    name: 'Banners',
    key: 'banners',
    icon: <Icon fontSize="medium">image</Icon>,
    route: "/banners",
    component: <Banners />
  },{
    type: 'collapse',
    name: 'Maps',
    key: 'maps',
    icon: <Icon fontSize="small">maps</Icon>,
    route: "/maps",
    component: <MapComponent apiKey={getMapApiKey()} />,
  },
  {
    type: 'collapse',
    name: 'Queries',
    key: 'queries',
    icon: <Icon fontSize="small">email</Icon>,
    route: "/queries",
    component: <Queries tableHeader="Queries" />,
  },
];

export default routes;
