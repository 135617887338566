import {makeStyles} from '@mui/styles';

export default makeStyles(({ breakpoints }) => ({
    root: {
        "& .MuiFormLabel-root": {
            lineHeight: 1.2
        }
    },
    categoriesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '10px 10px 10px 10px',
    },
    container: {
        height: '100%',
        [breakpoints.down('sm')]: {
            padding: 33,
        },
        '& h3': {
            marginBottom: 8,
        }
    },
    addButton: {
        '&.MuiButton-root': {
            borderRadius: 35,
            borderColor: '#1A73E8',
        }
    },
    inputFieldContainer: {
        '& > div': {
            margin: '1rem 0'
        }
    },
    addImageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 88,
        height: 88,
        background: '#F9FAFF',
        border: '1px dashed #DCDFE7',
        borderRadius: 12,
        cursor: 'pointer',
        '& label': {
            cursor: 'pointer',
        }
    },
    imagesContainer: {
        display: 'flex',
        overflow: 'auto',
    },
    categoryImage: {
        margin: '1rem 0 0 0',
        borderRadius: 12,
        height: 88,
        width: 88,
    }
}))
