import {call, takeLatest} from 'redux-saga/effects';
import { getActiveUsersApi, getConversionRateApi, getGrossTransactionVolumeApi, getMessagesSentFromCustomersApi, getOffersConvertedToInvoicesApi, getOffersSentToCustomersApi, getProvidersApi } from './dashboard.apis';
import { GET_ACTIVE_USERS, GET_CONVERSION, GET_GMV, GET_MESSAGES_FROM_CUSTOMERS, GET_OFFERS_CONVERTED, GET_OFFERS_TO_CUSTOMERS, GET_PROVIDERS } from './dashboard.constants';

function* getProvidersAddedSaga({payload, callback}) {
    try {
        const res = yield call(getProvidersApi)
        callback(res, null)
    } catch(err) {
        callback(null, err)
    }
}

function* getActiveUsersSaga({payload, callback}) {
    try {
        const res = yield call(getActiveUsersApi)
        callback(res, null)
    } catch(err) {
        callback(null, err)
    }
}

function* getConversionSaga({payload, callback}) {
    try {
        const res = yield call(getConversionRateApi)
        callback(res, null)
    } catch(err) {
        callback(null, err)
    }
}

function* getMessagesSentFromCustomersSaga({payload, callback}) {
    try {
        const res = yield call(getMessagesSentFromCustomersApi)
        callback(res, null)
    } catch(err) {
        callback(null, err)
    }
}

function* getOffersSentToCustomersSaga({payload, callback}) {
    try {
        const res = yield call(getOffersSentToCustomersApi)
        callback(res, null)
    } catch(err) {
        callback(null, err)
    }
}

function* getGMVSaga({payload, callback}) {
    try {
        const res = yield call(getGrossTransactionVolumeApi)
        callback(res, null)
    } catch(err) {
        callback(null, err)
    }
}

function* getOffersConvertedToInvoicesSaga({payload, callback}) {
    try {
        const res = yield call(getOffersConvertedToInvoicesApi)
        callback(res, null)
    } catch(err) {
        callback(null, err)
    }
}

export default function* metricsSagas() {
    yield takeLatest(GET_PROVIDERS, getProvidersAddedSaga)
    yield takeLatest(GET_ACTIVE_USERS, getActiveUsersSaga)
    yield takeLatest(GET_CONVERSION, getConversionSaga)
    yield takeLatest(GET_MESSAGES_FROM_CUSTOMERS, getMessagesSentFromCustomersSaga)
    yield takeLatest(GET_OFFERS_TO_CUSTOMERS, getOffersSentToCustomersSaga)
    yield takeLatest(GET_GMV, getGMVSaga)
    yield takeLatest(GET_OFFERS_CONVERTED, getOffersConvertedToInvoicesSaga)
}