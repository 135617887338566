import {call, takeLatest} from 'redux-saga/effects';
import { getRequestsAPI } from './requests.apis';
import { GET_REQUESTS } from './requests.constants';

function* getRequests({payload, callback}) {
    try {
        const res = yield call(getRequestsAPI, payload)
        callback(res, null)
    } catch(err) {
        callback(null, err)
    }
}

export default function* requestsSagas() {
    yield takeLatest(GET_REQUESTS, getRequests)
}