import { LOGIN_SUCCESS, LOGOUT } from './sign-in.constants'

const initial = {
    isLoggedIn: false,
}

export default function signInReducer (state=initial, { type, payload }) {
    switch(type) {
        case LOGIN_SUCCESS: return { ...state, ...payload }
        case LOGOUT: return { ...state, ...payload }
        default: return { ...state }
    }
}
