import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "container-components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "container-components/Navbars/DashboardNavbar";
import ReportsBarChart from "container-components/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "container-components/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "container-components/Cards/StatisticsCards/ComplexStatisticsCard";
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import { useDispatch } from "react-redux";
import { getActiveUsers, getConversion, getGMV, getMessagesFromCustomers, getOffersToCustomers, getProviders, getOffersConverted } from "./dashboard.actions";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const [gmv, setGMV] = useState(0);
  const [providersAdded, setProvidersAdded] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [offersConvertedToInvoices, setOffersConvertedToInvoices] = useState(0);
  const [messagesSentFromCustomers, setMessagesSentFromCustomers] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [offersSentToCustomers, setOffersSentToCustomers] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProviders((success, err) => err ? console.log(err) : setProvidersAdded(success)))
    dispatch(getActiveUsers((success, err) => err ? console.log(err) : setActiveUsers(success)))
    dispatch(getConversion((success, err) => err ? console.log(err) : setConversionRate(success)))
    dispatch(getGMV((success, err) => err ? console.log(err) : setGMV(success)))
    dispatch(getMessagesFromCustomers((success, err) => err ? console.log(err) : setMessagesSentFromCustomers(success)))
    dispatch(getOffersToCustomers((success, err) => err ? console.log(err) : setOffersSentToCustomers(success)))
    dispatch(getOffersConverted((success, err) => err ? console.log(err) : setOffersConvertedToInvoices(success)))
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="group"
                title="Daily Active Users"
                count={activeUsers}
                percentage={{
                  color: "",
                  amount: "",
                  label: "in last 30 days",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="group"
                title="Service Providers Added"
                count={providersAdded}
                percentage={{
                  color: "",
                  amount: "",
                  label: "in last 30 days",
                }}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="message"
                title="Messages sent to Service Providers"
                count={messagesSentFromCustomers}
                percentage={{
                  color: "",
                  amount: "",
                  label: "in last 30 days",
                }}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="redeem"
                title="Offers sent to customers"
                count={offersSentToCustomers}
                percentage={{
                  color: "",
                  amount: "",
                  label: "in last 30 days",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="receipt"
                title="Offers converted to Invoices"
                count={offersConvertedToInvoices}
                percentage={{
                  color: "",
                  amount: "",
                  label: "in last 30 days",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="percentage"
                title="Conversion Rate"
                count={conversionRate?.toFixed(2)}
                percentage={{
                  color: "",
                  amount: "",
                  label: "in last 30 days",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="money"
                title="GMV"
                count={gmv?.toFixed(2)}
                percentage={{
                  color: "",
                  amount: "",
                  label: "in last 30 days",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
