import {  call } from "redux-saga/effects";
import { resetPasswordAPI, sendEmailAPI } from "./reset.apis";

export function* sendEmailSaga({callback}) {
    try {
        const res = yield call(sendEmailAPI);
        callback(res, null);
    } catch(err) {
        callback(null, err);
    }
}

export function* resetPasswordSaga({payload, callback}) {
    try {
        const res = yield call(resetPasswordAPI, payload);
        callback(res, null);
    } catch(err) {
        callback(null, err);
    }
}