import { Fragment, useState } from "react";
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { login } from "./sign-in.actions";


function Basic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });

  const handleChange = (key, value) => {
    setCredentials({...credentials, [key]: value});
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(credentials.username === '' || credentials.password === '') {
      setError(true);
      return
    }

    dispatch(login(credentials, (success, err) => {
      setCredentials({username: '', password: ''});
      if(err){
        setError(true);
        return;
      } else {
        localStorage.setItem('JWTToken', success.authentication);
        navigate('/dashboard');
      }
    }))
  }

  const closeSnackBar = () => {
    setError(false);
  }

  return (
    <Fragment>
      <MDSnackbar
        color="error"
        title="Authentication Error"
        icon="warning"
        dateTime="now"
        content="Please enter valid username and password"
        open={error}
        onClose={closeSnackBar}
        close={closeSnackBar}
      />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="username" label="Username" fullWidth value={credentials.username} onChange={(e) => handleChange('username', e.target.value)}/>
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" fullWidth value={credentials.password} onChange={(e) => handleChange('password', e.target.value)}/>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="outlined" color="info" fullWidth onClick={handleSubmit}>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={1} mb={1} display="flex" justifyContent="flex-end">
              <MDTypography sx={{fontSize: 16}} >
                <Link to="/authentication/reset"><span style={{ color: '#1877f2'}}>Forgot Password?</span></Link>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </Fragment>
  );
}

export default Basic;
