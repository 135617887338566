import {call, takeLatest} from 'redux-saga/effects';
import { getOrdersAPI } from './orders.apis';
import { GET_ORDERS } from './orders.constants';

function* getOrders({payload, callback}) {
    try {
        const result = yield call(getOrdersAPI, payload);
        callback(result, null)
    } catch (err) {
        callback(null, err)
    }
}

function* ordersSagas() {
    yield takeLatest(GET_ORDERS, getOrders)
}

export default ordersSagas;