import axios from 'axios';

export default function initializeAxios(basePath) {
   axios.interceptors.request.use(
      (req) => {
         req.url = req.url.replace('{server}', basePath)
         req.headers.authorization = localStorage.getItem('JWTToken');
         return req;
      },
      (err) => {
         return Promise.reject(err);
      }
   );
   axios.interceptors.response.use(
      (res) => {
         return res;
      },
      (err) => {
         if(err.response && err.response.status === 401) {
            localStorage.clear();
            setTimeout(()=>{
               window.location.replace('/authentication/sign-in');
            }, 1000)
         }
         return Promise.reject(err);
      }
   );
}
