import DashboardLayout from "container-components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "container-components/Navbars/DashboardNavbar";
import CustomerHeader from "./customer.header";
import MDBox from "components/MDBox";
import CustomerProfileCard from "./customer.profile";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getCustomer, getCustomerOrders, deactivateCustomer, activateCustomer } from "./customer.actions";
import { formatCustomer, orderData } from "./customer";
import { CustomTable } from "container-components/Tables/DataTable";
import CustomAccordion from "components/Accordion";
import MDDialog from "components/MDDialog";
import MDSnackbar from "components/MDSnackbar";

function CustomerProfile() {
    const dispatch = useDispatch();
    const { customerId } = useParams();
    const [snackbar, setSnackbar] = useState({
        color: "",
        title: "",
        icon: "",
        content: "",
        open: false,
        dateTime: "now",
        onClose: null,
        close: null,
    });
    const [dialog, setDialog] = useState({
        text: '',
        title: '',
        onConfirm: null,
        confirmationText: '',
        open: false,
        onClose: null,
    });
    const [customer, setCustomer] = useState({});
    const [data, setData] = useState({ columns: [], rows: [], totalPages: 0, totalCount: 0 });

    const setErrorSnack = (title, content) => {
        setSnackbar({
            title,
            content,
            close: () => setSnackbar({ ...snackbar, open: false }),
            onClose: () => setSnackbar({ ...snackbar, open: false }),
            color: "error",
            icon: "warning",
            open: true
        });
    }

    const getData = ({ pageSize, pageIndex, search }) => {
        dispatch(getCustomerOrders({ userId: customerId, page: pageIndex + 1, limit: pageSize }, (success, err) => {
            if (err) {
                setErrorSnack('Unknown error', "Unexpected error happened fetching orders");
            } else {
                setData({ ...orderData(success.items), totalCount: success.totalCount, totalPages: Math.ceil(success.totalCount / pageSize) });
            }
        }))
    }

    const onDeactivate = () => {
        dispatch(deactivateCustomer(customerId, (_, err) => {
            if (err) {
                setErrorSnack('Unknown error', "Could not deactivate customer");
            } else {
                setCustomer({ ...customer, deactivated: true });
            }
            setDialog({ ...dialog, open: false });
        }))
    }

    const handleDeactivate = () => {
        setDialog({
            text: 'Are you sure you want to deactivate this customer ?',
            title: 'Deactivate',
            onConfirm: onDeactivate,
            confirmationText: 'Deactivate',
            open: true,
            onClose: () => setDialog({ ...dialog, open: false }),
        });
    }

    const onActivate = () => {
        dispatch(activateCustomer(customerId, (success, err) => {
            if (err) {
                setErrorSnack('Unknown error', "Could not activate customer");
            } else {
                setCustomer({ ...customer, deactivated: false });
            }
            setDialog({ ...dialog, open: false });
        }))
    }

    const handleActivate = () => {
        setDialog({
            text: 'Are you sure you want to activate this customer ?',
            title: 'Activate',
            onConfirm: onActivate,
            confirmationText: 'Activate',
            open: true,
            onClose: () => setDialog({ ...dialog, open: false }),
        });
    }

    useEffect(() => {
        if (customerId) {
            dispatch(getCustomer(customerId, (success, err) => {
                if (err) {
                    setErrorSnack('Unknown error', "Could not fetch customer profile");
                } else {
                    setCustomer(formatCustomer(success));
                }
            }))
        }
    }, [])

    const { name, image, id, phone, email, rating, deactivated, disabled } = customer;
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDDialog {...dialog} />
            <MDSnackbar {...snackbar} />
            <CustomerHeader
                name={name}
                image={image}
                buttons={{
                    activate: {
                        disabled: !deactivated || disabled,
                        handleClick: handleActivate
                    },
                    deactivate: {
                        disabled: deactivated || disabled,
                        handleClick: handleDeactivate
                    },
                }}
            >
                <MDBox mt={5} mb={3}>
                    <CustomAccordion title="Profile Information" description={'Customer Details'} expanded={true}>
                        <CustomerProfileCard
                            info={{
                                customerId: id, phone, email, rating, deactivated: `${deactivated}`, disabled: `${disabled}`
                            }}
                            shadow={false}
                        />
                    </CustomAccordion>
                    <CustomAccordion title="Order Details" description={`Orders for ${name}`}>
                        <CustomTable
                            columns={[...data.columns]}
                            data={[...data.rows]}
                            isSorted={false}
                            showTotalEntries
                            noEndBorder
                            loading={false}
                            fetchData={getData}
                            pageCount={data.totalPages}
                            totalCount={data.totalCount}
                            handleRowClick={(id) => console.log(id)}
                        />
                    </CustomAccordion>
                </MDBox>
            </CustomerHeader>
        </DashboardLayout>
    )
}

export default CustomerProfile;