import {call, takeLatest} from 'redux-saga/effects';
import { getAddressesAPI } from './maps.apis';
import { GET_ADDRESSES } from './maps.constants';

function* getAddressesSaga({payload, callback}) {
    try {
        const res = yield call(getAddressesAPI, payload);
        callback(res, null);
    } catch(err) {
        callback(null, err);
    }
}

export default function* mapSagas() {
    yield takeLatest(GET_ADDRESSES, getAddressesSaga)
}