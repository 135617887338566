import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Rating from '@mui/material/Rating';
import MDTypography from "components/MDTypography";

function ProviderProfileCard({ info, shadow }) {
  const labels = [];
  const values = [];

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" justifyContent="space-between" py={1} pr={5} pl={5}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}:
      </MDTypography>
      <MDTypography variant="button" display="flex" fontWeight="regular" color="text" justifyContent="center">
        { label === 'rating' ? <Rating value={+values[key]}  precision={0.1} readOnly /> : values[key]}
      </MDTypography>
    </MDBox>
  ));

  const split = renderItems.length > 10 ? Math.ceil(renderItems.length) : 5;
  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none", display: 'flex', flexDirection:"row" }}>
      <MDBox display="flex" flexDirection="column" justifyContent="flex-start" width="50%" pl={2}>
        {renderItems.slice(0, split)}
      </MDBox>
      <MDBox display="flex" flexDirection="column" justifyContent="flex-start" width="50%" pl={2}>
        {renderItems.slice(split)}
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
ProviderProfileCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
ProviderProfileCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  shadow: PropTypes.bool,
};

export default ProviderProfileCard;
