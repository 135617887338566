import DashboardLayout from "container-components/LayoutContainers/DashboardLayout"
import DashboardNavbar from "container-components/Navbars/DashboardNavbar"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { acceptRequest, declineRequest, getRequest } from "./request.actions"
import RequestHeader from "./request.header";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import RequestProfile from './request.profile';
import MDTypography from "components/MDTypography";
import Chip from "@mui/material/Chip"
import ArrowRight from '@mui/icons-material/ArrowForwardOutlined';
import MDDialog from "components/MDDialog"
import MDSnackbar from "components/MDSnackbar";

const ChangeItem = ({ label, value }) => {
    return (
        <MDBox key={label} width="100%" display="flex" justifyContent="space-between" py={1} pr={1} pl={1}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" mr={3}>
                {label}:
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
                {value}
            </MDTypography>
        </MDBox>
    )
}

const ChangeRequest = ({ from, to }) => {
    return (
        <MDBox width="100%" display="flex" alignItems="center" justifyContent="space-evenly" mb={3}>
            <Chip label={<ChangeItem {...from} />} sx={{ ml: 1 }} />
            <ArrowRight fontSize="medium" />
            <Chip label={<ChangeItem {...to} />} sx={{ ml: 1 }} />
        </MDBox>
    )
}

export const Request = () => {
    const dispatch = useDispatch();
    const [render, setRender] = useState(false);
    const { requestId } = useParams();
    const [requestData, setRequestData] = useState({ user: {}, request: {} });
    const [dialog, setDialog] = useState({
        text: '',
        title: '',
        confirmationText: '',
        open: false,
        onConfirm: null,
        onClose: null,
    });
    const [snackbar, setSnackbar] = useState({
        color: "",
        title: "",
        icon: "",
        content: "",
        open: false,
        dateTime: "now",
        onClose: null,
        close: null,
    });

    const setSuccessSnack = (title, content) => {
        setSnackbar({
            title,
            content,
            close: () => setSnackbar({...snackbar, open: false}),
            onClose: () => setSnackbar({...snackbar, open: false}),
            color: 'info',
            icon: 'info',
            open: true,
        })
    }

    const setErrorSnack = (title, content) => {
        setSnackbar({
            title,
            content,
            close: () => setSnackbar({ ...snackbar, open: false }),
            onClose: () => setSnackbar({ ...snackbar, open: false }),
            color: "error",
            icon: "warning",
            open: true
        });
    }

    const formatData = (user, request) => {
        const { id, name, businessType, phone, email, category, overview, firstName, lastName, profileURL, experience } = user;
        const updatedName = name ? name : `${firstName || ''} ${lastName || ''}`;

        let requestTypes = [];
        const { providerId, providerType, createdAt, newBusinessType, newFirstName, newLastName, newName, status } = request;
        if (newBusinessType) {
            requestTypes.push({ type: 'business', from: businessType, to: newBusinessType })
        }
        if (newFirstName || newLastName || newName) {
            const newName = providerType === 'serviceProvider' ? `${newFirstName || ''} ${newLastName || ''}` : `${newName || ''}`;
            requestTypes.push({ type: 'name', from: updatedName, to: newName })
        }

        return {
            user: {
                id,
                name: updatedName,
                category, phone, email,
                description: overview,
                image: profileURL,
                businessType,
                experience: experience || '-',
            },
            request: {
                status,
                requestTypes,
                createdAt
            }
        }
    }

    const handleAccept = () => {
        dispatch(acceptRequest(requestId, (success, err) =>{
            if(err) {
                setErrorSnack('Unknown error', "Unexpected error happened accepting request");
            } else {
                setSuccessSnack('Success', 'Accepted changes successfully');
            }
            setRender(!render);
            setDialog({ ...dialog, open: false })
        }))
    }

    const handleDecline = () => {
        dispatch(declineRequest(requestId, (success, err) =>{
            if(err) {
                setErrorSnack('Unknown error', "Unexpected error happened declining request");
            } else {
                setSuccessSnack('Success', 'Declined changes successfully');
            }
            setRender(!render);
            setDialog({ ...dialog, open: false })
        }))
    }

    const handleAction = (action) => {
        const actionType = action ? 'Accept' : 'Decline';
        setDialog({
            text: `Are you sure you want to ${actionType.toLowerCase()} this changes ?`,
            title: actionType,
            confirmationText: actionType,
            open: true,
            onClose: () => setDialog({ ...dialog, open: false }),
            onConfirm: action ? handleAccept : handleDecline,
        });
    }

    useEffect(() => {
        dispatch(getRequest(requestId, (success, err) => {
            if (err) {
                setErrorSnack('Unknown error', "Unexpected error happened fetching request");
            } else {
                setRequestData(formatData(success.provider, success.request))
            }
        }))
    }, [render])

    const { id, name, image, phone, description, email, category, experience, businessType } = requestData.user;
    const { requestTypes } = requestData.request;
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDDialog {...dialog} />
            <MDSnackbar {...snackbar} />
            <RequestHeader
                name={name}
                image={image}
                disableButtons={requestData.request?.status !== 'requested'}
                buttons={{
                    accept: {
                        handleClick: () => handleAction(true),
                    },
                    decline: {
                        handleClick: () => handleAction(false),
                    },
                }}
            >
                <MDBox mt={5} mb={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} xl={5} sx={{ display: "flex" }}>
                            <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                            <RequestProfile
                                title="profile information"
                                description={description}
                                info={{
                                    id, phone, email, category, businessType, experience, requestStatus :requestData.request?.status?.toUpperCase(),
                                }}
                                shadow={false}
                            />
                            <Divider orientation="vertical" sx={{ mx: 0 }} />
                        </Grid>
                        <Grid item xs={12} xl={7}>
                            <MDBox width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                <MDTypography width="100%" variant="h6" fontWeight="medium" sx={{ textAlign: 'center' }}>
                                    Changes Requested
                                    <Divider />
                                </MDTypography>
                                <MDBox width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                                    {
                                        requestTypes && requestTypes.map(({ from, type, to }) => {
                                            return <ChangeRequest from={{ label: type, value: from }} to={{ label: `New ${type}`, value: to }} />
                                        })
                                    }
                                </MDBox>
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
            </RequestHeader>
        </DashboardLayout>
    )
}