export const getOrderData = (orderData) => {
    const {orderId, createdAt, status, statusDescription, bookingAmount, totalPrice, customerLocation, estimate,
        partialRefundId, residualRefundId, partialPaymentId, residualPaymentId, services, scheduledTime} = orderData;
    return {
        id: orderId, createdAt: toDateTimeString(createdAt), status: replaceUnderscore(status), partialRefundId, residualRefundId,
        statusDescription, bookingAmount, totalPrice, estimate, scheduledTime: toDateTimeString(scheduledTime),
        initialPaymentId: partialPaymentId, finalPaymentId: residualPaymentId, services, serviceLocation: addressToString(customerLocation)
    }
}

const toDateTimeString = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.toLocaleTimeString()}, ${date.toLocaleDateString()}`
}

const replaceUnderscore = (value) => {
    return value ? value.replaceAll("_", " ") : "";
}

const addressToString = (address) => {
    return address.city
}

export const getCustomerData = (customerData) => {
    const {id, firstName, lastName, phone, email, rating} = customerData;
    return {
        id, phone, email, name: `${firstName || ''} ${lastName || ''}`, rating
    }
}

export const getProviderData = (providerData) => {
    const {id, phone, email, name, firstName, lastName, rating, category} = providerData;
    return {
        id, phone, email, name: name ? name : `${firstName || ''} ${lastName || ''}`, rating, category
    }
}