import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';

export default function CategoryCard({name, image, description, isPopular, cardClick, onEdit, onDelete}) {
  return (
    <Card sx={{ maxWidth: 300, width: 300, margin: 2 }}>
      <CardActionArea>
        <CardMedia
          sx={{ height: 170 }}
          image={image}
          alt={name}
          onClick={cardClick}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {name && name.toUpperCase()}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="info" onClick={onEdit}>
          Edit
        </Button>
        <Button size="small" color="error" sx={{color: 'red'}} onClick={onDelete}>
          Delete
        </Button>
      </CardActions>
    </Card>
  );
}
