import DashboardLayout from "container-components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "container-components/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import { CustomTable } from "container-components/Tables/DataTable/index";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getQueries } from "./queries.actions";
import queriesData from './queries.data';

export default function Queries({tableHeader}) {
    const dispatch = useDispatch();
    const [data, setData] = useState({ columns: [], rows: [], totalPages: 0, totalCount: 0 });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [snackbar, setSnackbar] = useState({
        color: "",
        title: "",
        icon: "",
        content: "",
        open: false,
        dateTime: "now",
        onClose: null,
        close: null,
    });

    const setErrorSnack = (title, content) => {
        setSnackbar({
            title,
            content,
            close: () => setSnackbar({ ...snackbar, open: false }),
            onClose: () => setSnackbar({ ...snackbar, open: false }),
            color: "error",
            icon: "warning",
            open: true
        });
    }

    const getData = ({ pageSize, pageIndex, filters: {status} }) => {
        setLoading(true);
        dispatch(getQueries({ page: pageIndex + 1, limit: pageSize, status }, (success, err) => {
            if (err) {
                setErrorSnack('Unknown error', 'Unexpected error happened during fetching queries')
            } else {
                setData({...queriesData(success.items), totalCount: success.totalCount, totalPages: Math.ceil(success.totalCount / pageSize)});                
            }
            setLoading(false)
        }))
    }

    const handleRowClick = (id) => {
        console.log(id);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDSnackbar {...snackbar} />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    {tableHeader}
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <CustomTable
                                    columns={[...data.columns]}
                                    data={[...data.rows]}
                                    isSorted={false}
                                    showTotalEntries
                                    noEndBorder
                                    disableSearch
                                    loading={loading}
                                    fetchData={getData}
                                    pageCount={data.totalPages}
                                    totalCount={data.totalCount}
                                    handleRowClick={handleRowClick}
                                    // filterOptions={[{
                                    //     name: 'Status',
                                    //     options: [
                                    //         {label: 'All', value: 'all'},
                                    //         {label: 'Requested', value: 'requested'},
                                    //         {label: 'Accepted', value: 'accepted'},
                                    //         {label: 'Rejected', value: 'rejected'},
                                    //     ],
                                    //     type: 'radio',
                                    //   }]}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    )
}