import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { styled } from '@mui/material/styles'

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog"
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import useStyles from './category-management.styles'
import { uploadImage, addNewCategory, editCategoryDetails } from './category-management.actions'

const defaultService = { name: '', description: '', images: [], active: true, categoryId: null, isPopular: false }

const Input = styled('input')({
    display: 'none',
});

function AddImage({ setImages }) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const ref = useRef()
    const [loader, setLoader] = useState(false)

    const handleFileUpload = (evt) => {
        const photo = evt.target.files[0];
        const formData = new FormData();

        formData.append("file", photo);
        setLoader(true)
        dispatch(uploadImage(formData, (success, err) => {
            if (err) {
                console.log(err)
            } else {
                setLoader(false)
                setImages(success);
            }
        }))
    }

    return (
        <div className={classes.addImageContainer} onClick={() => ref.current.dispatchEvent(new MouseEvent('click'))}>
            <label htmlFor="upload-profile-image">
                <Input ref={ref} width="100%" accept="image/*" id="upload-profile-image" type="file" onChange={handleFileUpload} disabled={loader} />
                <AddPhotoAlternateIcon fontSize="large" />
            </label>
        </div>
    )
}

export default function AddServiceDialog({ open, onClose, defaultValues = {}, rerender, setRerender }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [service, setService] = useState(defaultService);
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (defaultValues) {
            const { images } = defaultValues;
            setService(defaultValues);
            setImages(images);
        } else {
            setService(defaultService);
        }
    }, [defaultValues])

    const handleClose = () => {
        setService(defaultService);
        setImages([]);
        onClose();
    }

    const onSave = () => {
        const callback = (success, err) => {
            if (err) {
                console.log(err);
            } else {
                setLoading(false);
                handleClose();
                setRerender(!rerender);
            }
        }
        if (!service.id) {
            dispatch(addNewCategory({ ...service, image: images && images.length ? images[0] : null }, callback))
        } else {
            dispatch(editCategoryDetails({ id: service.id, payload: { ...service, image: images && images.length ? images[0] : null } }, callback))
        }
    }

    const handleEventChange = (val, type) => setService(serv => ({ ...serv, [type]: val }))

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Category</DialogTitle>
            <DialogContent>
                <Box display="flex" justifyContent="space-evenly" alignItems="center" sx={{ height: 200 }}>
                    <Box display="flex" alignItems="space-evenly" flexDirection="column" justifyContent="center">
                        <TextField
                            variant="outlined"
                            label='Category name'
                            value={service.name}
                            margin="dense"
                            fullWidth
                            required
                            classes={classes}
                            sx={{ width: 350 }}
                            onChange={evt => handleEventChange(evt.target.value, 'name')}
                        />
                        <TextField
                            variant="outlined"
                            label='Category description'
                            value={service.description}
                            margin="dense"
                            classes={classes}
                            fullWidth
                            multiline
                            rows={3}
                            onChange={evt => handleEventChange(evt.target.value, 'description')}
                        />
                        <div style={{ paddingLeft: '0.1rem' }}>
                            Popular Category
                            <Switch
                                checked={service.isPopular}
                                inputProps={{ "aria-label": "Is popular" }}
                                onChange={() => handleEventChange(!service.isPopular, 'isPopular')}
                                color="default"
                            />
                        </div>
                    </Box>
                    <Divider orientation="vertical" variant="fullWidth" flexItem textAlign="center" />
                    <Box sx={{ padding: '0 1rem 0 0', maxWidth: 150, width: 150, height: 200 }}>
                        <AddImage setImages={setImages} images={images} />
                        {images.map((img) => <img src={img} alt='category' className={classes.categoryImage} />)}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color="error" sx={{ color: 'red' }} onClick={onClose}>Cancel</Button>
                <Button onClick={onSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}
