export const formatData = (providers) => {
    let addresses = [];
    providers.map(({name, address}) => {
        if(address) {
            const {fullAddress, latitude, longitude} = address;
            addresses.push({
                name,
                latitude,
                longitude,
                address: fullAddress,
            })
        }
    })

    return addresses;
}