import { getCategoriesAPI } from "App.apis";
import { GET_CATEGORIES_LIST, GET_CATEGORIES_SUCCESS } from "App.constants";
import { takeLatest, call, put } from "redux-saga/effects";

function* getCategoriesSaga() {
    try {
        let result = [];
        const res = yield call(getCategoriesAPI);
        if(res.length) {
            result = res.map(({name}) => name)
        }
        yield put({type: GET_CATEGORIES_SUCCESS, payload: result})
    } catch(err) {
        console.log(err);
    }
}

export default function* appSagas() {
    yield takeLatest(GET_CATEGORIES_LIST, getCategoriesSaga)
}