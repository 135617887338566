import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MDBox from 'components/MDBox';
import MDInput from "components/MDInput";

import Autocomplete from "@mui/material/Autocomplete";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MDTypography from 'components/MDTypography';

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({
        '.MuiTypography-root': {
            fontWeight: 500,
        },
    }),
);

export default function Filters({ filterOptions, handleFilters }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [category, setCategory] = React.useState('All');
    const [value, setValue] = React.useState('requested');
    const open = Boolean(anchorEl);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (!open) {
            handleFilters({ category, status: value });
        }
    }, [open])

    return (
        <div>
            <Button
                id="demo-customized-button"
                color="info"
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ color: '#1A73E8' }}
            >
                Filters
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MDBox>
                    {
                        filterOptions.map(({ type, name, options }) => {
                            if (type === 'list') {
                                return (
                                    <MDBox display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                                        <MDTypography variant="h6" fontWeight="regular" sx={{ marginLeft: 2, marginRight: 2 }}>
                                            {name}:
                                        </MDTypography>
                                        <Autocomplete
                                            disableClearable
                                            value={category.toString()}
                                            options={options}
                                            onChange={(event, newValue) => {
                                                setCategory(newValue);
                                            }}
                                            size="small"
                                            sx={{ width: "12rem" }}
                                            renderInput={(params) => <MDInput {...params} />}
                                        />
                                    </MDBox>
                                )
                            } else if (type === 'radio') {
                                return (
                                    <MDBox display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                                        <MDTypography variant="h6" fontWeight="regular" sx={{ marginLeft: 2, marginRight: 2 }}>
                                            {name}:
                                        </MDTypography>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={value}
                                            onChange={handleChange}
                                        >
                                            {
                                                options.map(({ label, value }) => <StyledFormControlLabel value={value} control={<Radio />} label={label} />)
                                            }
                                        </RadioGroup>
                                    </MDBox>
                                )
                            }
                        })
                    }
                </MDBox>
            </Menu>
        </div>
    );
}
