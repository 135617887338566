import MDTypography from "components/MDTypography";

const replaceUnderscore = (input) => {
    return input.replaceAll('_', ' ')
}

const humanTimestamp = (stringTimestamp) => {
    const timestamp = new Date(stringTimestamp);
    return `${timestamp.toLocaleTimeString()}, ${timestamp.toLocaleDateString()}`
}

export default function data(rowData) {
    return {
        columns: [
            { Header: "order Id", accessor: "orderId", align: "left"},
            { Header: "created At", accessor: "createdAt", align: "center" },
            { Header: "status", accessor: "status", align: "center" },
            { Header: "status description", accessor: "statusDescription", align: "center" },
            { Header: "Estimate price", accessor: "estimate", align: "center" },
            { Header: "Initial payment Id", accessor: "partialPaymentId", align: "center" },
            { Header: "total Price", accessor: "totalPrice", align: "center" },
            { Header: "Final payment Id", accessor: "residualPaymentId", align: "center" },
        ],

        rows: rowData.map(({orderId, createdAt, status, partialPaymentId, statusDescription, totalPrice, residualPaymentId, estimate}) => {
            return {
                id: orderId,
                orderId: <MDTypography component="p" variant="caption" color="text" fontWeight="medium"> {orderId} </MDTypography>,
                createdAt: <MDTypography component="p" variant="caption" color="text" fontWeight="medium"> {humanTimestamp(createdAt)} </MDTypography>,
                status: <MDTypography component="p" variant="caption" color="text" fontWeight="medium">{replaceUnderscore(status)}</MDTypography>,
                statusDescription: <MDTypography component="p" variant="caption" color="text" fontWeight="medium">{statusDescription}</MDTypography>,
                totalPrice: <MDTypography component="p" variant="caption" color="text" fontWeight="medium">${totalPrice}</MDTypography>,
                estimate: <MDTypography component="p" variant="caption" color="text" fontWeight="medium">${estimate}</MDTypography>,
                partialPaymentId: <MDTypography component="p" variant="caption" color="text" fontWeight="medium">{partialPaymentId}</MDTypography>,
                residualPaymentId: <MDTypography component="p" variant="caption" color="text" fontWeight="medium">{residualPaymentId}</MDTypography>,
                //partialRefundId: <MDTypography component="p" variant="caption" color="text" fontWeight="medium">{partialRefundId}</MDTypography>,
                //residualRefundId: <MDTypography component="p" variant="caption" color="text" fontWeight="medium">{residualRefundId}</MDTypography>,
            };
        })
    };
}
