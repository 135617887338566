import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import PersonIcon from '@mui/icons-material/Person';

function AltPerson() {
  return (
    <MDBox
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '4.625rem', width: '4.625rem', borderRadius: 20, boxShadow: '0rem 0.3125rem 0.625rem 0rem rgb(0 0 0 / 12%)' }}
    >
      <PersonIcon fontSize="large" />
    </MDBox>
  )
}

function DisabledDisplay({ children, disabled }) {
  return disabled ? null : children;
}

function CustomerHeader({ children, name, image, buttons: { activate, deactivate } }) {
  return (
    <MDBox position="relative" mb={5}>
      <Card
        sx={{
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <MDBox display="flex" width="100%">
          <MDBox display="flex" width="50%" alignItems="center">
            {
              image ?
                <MDAvatar src={image} alt="profile-image" size="xl" shadow="sm" /> :
                <AltPerson />
            }
            <MDTypography variant="h5" fontWeight="medium" sx={{ ml: 2 }}>
              {name}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" width="50%" alignItems="center" justifyContent="flex-end">
            <DisabledDisplay disabled={activate.disabled}>
              <MDButton disabled={activate.disabled} variant="outlined" color="info" sx={{ mr: 1, mt: 1 }} onClick={activate.handleClick}>Activate</MDButton>
            </DisabledDisplay>
            <DisabledDisplay disabled={deactivate.disabled}>
              <MDButton disabled={deactivate.disabled} variant="outlined" color="error" sx={{ mr: 1, mt: 1 }} onClick={deactivate.handleClick}>Deactivate</MDButton>
            </DisabledDisplay>
          </MDBox>
        </MDBox>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
CustomerHeader.defaultProps = {
  children: "",
};

// Typechecking props for the Header
CustomerHeader.propTypes = {
  children: PropTypes.node,
};

export default CustomerHeader;
