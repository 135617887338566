import BasicLayout from "layouts/authentication/components/BasicLayout";
import SignIn from 'layouts/authentication/sign-in';
import Reset from 'layouts/authentication/reset-password';
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Card from "@mui/material/Card";

export default function LoginComponent({type}) {
    return (
        <BasicLayout image={bgImage}>
            <Card>
                {
                    type === 'login' ? <SignIn /> : <Reset />
                }
            </Card>
        </BasicLayout>
    )
}