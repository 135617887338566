import axios from "axios";

export const getServiceProvidersAPI = ({page, limit, search, category, verified}) => {
    let verifiedQuery = '';
    if(verified !== null) {
        verifiedQuery = `&verified=${verified}`
    }

    let categoryQuery = '';
    if(category && category != 'All') {
        categoryQuery = `&category=${category}`;
    }

    let searchQuery = '';
    if(search) {
        searchQuery = `&search=${search}`;
    }

    return axios.get(`{server}/admin/users/providers?page=${page}&limit=${limit}${verifiedQuery}${categoryQuery}${searchQuery}`).then(res => res.data.success)
}