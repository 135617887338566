import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import PersonIcon from "@mui/icons-material/Person";

function AltPerson() {
  return (
    <MDBox
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '4.625rem', width: '4.625rem', borderRadius: 20, boxShadow: '0rem 0.3125rem 0.625rem 0rem rgb(0 0 0 / 12%)' }}
    >
      <PersonIcon fontSize="large" />
    </MDBox>
  )
}

function RequestHeader({ children, name, image, buttons: { accept, decline }, disableButtons }) {
  return (
    <MDBox position="relative" mb={5}>
      <Card
        sx={{
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            {
              image ? <MDAvatar src={image} alt="profile-image" size="xl" shadow="sm" /> :
                <AltPerson />
            }
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {name}
              </MDTypography>
            </MDBox>
          </Grid>
          {
            disableButtons ? null :
              <Grid item xs={12} md={6} lg={2} sx={{ ml: "auto" }}>
                <MDButton variant="outlined" color="info" sx={{ mr: 1, mt: 1 }} onClick={accept.handleClick}>Accept</MDButton>
                <MDButton variant="outlined" color="error" sx={{ mr: 1, mt: 1 }} onClick={decline.handleClick}>Decline</MDButton>
              </Grid>
          }
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
RequestHeader.defaultProps = {
  children: "",
};

// Typechecking props for the Header
RequestHeader.propTypes = {
  children: PropTypes.node,
};

export default RequestHeader;
