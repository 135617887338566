import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Service from "../serviceInfo";
import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

function OrderInfo(props) {
    const { noGutter, email, id, services, status, statusDescription, createdAt, bookingAmount, estimate, initialPaymentId,
        partialRefundId, residualRefundId, finalPaymentId, scheduledTime, serviceLocation, totalPrice, handleRefundClick } = props;
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
        <MDBox
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            bgColor={darkMode ? "transparent" : "grey-100"}
            borderRadius="lg"
            p={3}
            mb={noGutter ? 0 : 1}
            mt={2}
        >
            <MDBox width="100%" display="flex" flexDirection="column">
                <MDBox mb={1} lineHeight={0} display="flex" sx={{ justifyContent: "flex-start" }}>
                    <MDTypography variant="button" color="text" width="20%">
                        Order-Id:&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                        {id}
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0} display="flex" sx={{ justifyContent: "flex-start" }}>
                    <MDTypography variant="button" color="text" width="20%">
                        Status:&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="medium">
                        {status}
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0} display="flex" sx={{ justifyContent: "flex-start" }}>
                    <MDTypography variant="button" color="text" width="20%">
                        Status Description:&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="medium">
                        {statusDescription}
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0} display="flex" sx={{ justifyContent: "flex-start" }}>
                    <MDTypography variant="button" color="text" width="20%">
                        Service Location:&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="medium">
                        {serviceLocation}
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0} display="flex" sx={{ justifyContent: "flex-start" }}>
                    <MDTypography variant="button" color="text" width="20%">
                        Scheduled Time:&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="medium">
                        {scheduledTime}
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0} display="flex" sx={{ justifyContent: "flex-start" }}>
                    <MDTypography variant="button" color="text" width="20%">
                        Estimate:&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="medium">
                        {estimate}
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0} display="flex" sx={{ justifyContent: "flex-start" }}>
                    <MDTypography variant="button" color="text" width="20%">
                        Booking Amount:&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="medium">
                        {bookingAmount}
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0} display="flex" sx={{ justifyContent: "flex-start" }}>
                    <MDTypography variant="button" color="text" width="20%">
                        Total Price:&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="medium">
                        {totalPrice}
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0} display="flex" sx={{ justifyContent: "flex-start" }}>
                    <MDTypography variant="button" color="text" width="20%">
                        Initial Payment ID:&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="medium">
                        {initialPaymentId}
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0} display="flex" sx={{ justifyContent: "flex-start" }}>
                    <MDTypography variant="button" color="text" width="20%">
                        Initial Payment Refund ID:&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="medium">
                        {partialRefundId}
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0} display="flex" sx={{ justifyContent: "flex-start" }}>
                    <MDTypography variant="button" color="text" width="20%">
                        Final Payment ID:&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="medium">
                        {finalPaymentId}
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0} display="flex" sx={{ justifyContent: "flex-start" }}>
                    <MDTypography variant="button" color="text" width="20%">
                        Final Payment Refund ID:&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="medium">
                        {residualRefundId}
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0} display="flex" sx={{ justifyContent: "flex-start" }}>
                    <MDTypography variant="button" color="text" width="20%">
                        Services:&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                    {
                        services.length < 1 ? null :
                            <MDBox width="20%">
                                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                                    {
                                        services.map(({ id, name, quantity }, index) => <Service key={index} id={id} name={name} quantity={quantity} />)
                                    }
                                </MDBox>
                            </MDBox>
                    }
                </MDBox>
                <MDBox mb={1} lineHeight={0} display="flex" sx={{ justifyContent: "flex-start" }}>
                    <MDTypography variant="button" color="text" width="20%">
                        Created At:&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                        {createdAt}
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDButton
                variant="outlined"
                color="error"
                disabled={!(!!initialPaymentId || !!finalPaymentId) || (!!partialRefundId || !!residualRefundId)}
                onClick={handleRefundClick}
            >
                <Icon fontSize="large">replay</Icon>
                Refund
            </MDButton>
        </MDBox>
    );
}

OrderInfo.defaultProps = {
    noGutter: false,
    services: [],
};

OrderInfo.propTypes = {
    noGutter: PropTypes.bool,
    services: PropTypes.array,
};

export default OrderInfo;
