import {call, takeLatest, put} from 'redux-saga/effects';
import { RESET_PASSWORD, SEND_EMAIL } from '../reset-password/reset.constants';
import {sendEmailSaga, resetPasswordSaga} from '../reset-password/reset.sagas';
import { loginAPI } from './sign-in.apis';
import { LOGIN_ACTION, LOGIN_SUCCESS } from './sign-in.constants';

function* loginSaga({payload, callback}) {
    try {
        const result = yield call(loginAPI, payload);
        yield put({type: LOGIN_SUCCESS, payload: {isLoggedIn: true}})
        callback(result, null);
    } catch (err) {
        console.log(err)
        callback(null, err);
    }
}

function* loginSagas() {
    yield takeLatest(LOGIN_ACTION, loginSaga);
    yield takeLatest(SEND_EMAIL, sendEmailSaga);
    yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
}

export default loginSagas;