import { useEffect, useState } from 'react'

import DashboardLayout from "container-components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "container-components/Navbars/DashboardNavbar";
import Typography from "@mui/material/Typography";
import CategoryCard from './sub-category-card';

import AddIcon from '@mui/icons-material/AddCircleOutlined';

import useStyles from './sub-category-management.styles'
import AddSubCategoryDialog from './sub-category-dialog';
import { useDispatch } from 'react-redux';
import { deleteSubCategory, getSubCategories } from './sub-category-management.actions';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDDialog from 'components/MDDialog';
import { useParams } from 'react-router-dom';

export default function CategoryManagement() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const params = useParams();
    const [addDialog, setAddDialog] = useState(false);
    const [editCategoryValues, setEditCategoryValues] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [toDelete, setToDelete] = useState(null);
    const { categoryId } = useParams();

    useEffect(() => {
        if (categoryId) {
            dispatch(getSubCategories(categoryId, (success, err) => {
                if (err) {
                    console.log(err)
                } else {
                    setCategoryList(success)
                }
            }))
        }
    }, [rerender, categoryId]);

    const handleEdit = ({ id, name, description, image, isPopular }) => {
        setEditCategoryValues({ id, name, description, isPopular, images: [image] })
        setAddDialog(true);
    }

    const handleClose = () => {
        setEditCategoryValues(null);
        setAddDialog(false);
    }

    const handleDelete = () => {
        dispatch(deleteSubCategory({categoryId, id: toDelete.id}, (success, err) => {
            if (err) {
                console.log(err);
            } else {
                setToDelete(null);
                setRerender(!rerender);
            }
        }))
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDDialog
                text={`Are you sure you want to delete this sub category ?`}
                title={'Delete Sub Category'}
                onConfirm={handleDelete}
                confirmationText={'Delete'}
                open={!!toDelete}
                onClose={() => setToDelete(null)}
            />
            <MDBox>
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant='subtitle1' color='text.secondary'>
                        List of sub categories
                    </Typography>
                    <MDButton
                        variant="outlined"
                        startIcon={<AddIcon color="info" />}
                        onClick={() => setAddDialog(true)}
                        className={classes.addButton}
                    >
                        <Typography variant='content5' sx={{ color: '#1A73E8' }}>add new sub category</Typography>
                    </MDButton>
                </MDBox>
                <MDBox className={classes.categoriesContainer}>
                    {categoryList.map(ct => (
                        <CategoryCard
                            key={ct.id}
                            name={ct.name}
                            description={ct.description}
                            image={ct.image}
                            cardClick={() => console.log('card clicked', ct.id)}
                            onEdit={() => handleEdit(ct)}
                            onDelete={() => setToDelete(ct)}
                        />
                    ))}
                </MDBox>
                <AddSubCategoryDialog
                    open={addDialog}
                    onClose={handleClose}
                    defaultValues={editCategoryValues}
                    rerender={rerender}
                    setRerender={setRerender}
                    categoryId={categoryId}
                />
            </MDBox>
        </DashboardLayout>
    );
}
