import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'
import appReducer from '../App.reducer'
import signInReducer from 'layouts/authentication/sign-in/sign-in.reducer';

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({
  app: appReducer,
  user: signInReducer,
})

const rootReducerWrapper = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined
  }
  return rootReducer(state, action)
}

export const store = createStore(
  rootReducerWrapper,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
)

sagaMiddleware.run(rootSaga)
