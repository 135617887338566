import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { store } from './redux/store'
import App from "App";
import initializeAxios from './axios'


// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

initializeAxios(process.env.REACT_APP_API_SERVER);

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
