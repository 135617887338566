import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import PersonIcon from '@mui/icons-material/Person';

function AltPerson() {
  return (
    <MDBox
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '4.625rem', width: '4.625rem', borderRadius: 20, boxShadow: '0rem 0.3125rem 0.625rem 0rem rgb(0 0 0 / 12%)' }}
    >
      <PersonIcon fontSize="large" />
    </MDBox>
  )
}

function DisabledDisplay({ children, disabled }) {
  return disabled ? null : children;
}

function ProviderHeader({ children, name, providerType, image, buttons: { activate, deactivate, verify, decline } }) {
  return (
    <MDBox position="relative" mb={5}>
      <Card
        sx={{
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <MDBox display="flex" width="100%">
          <MDBox display="flex" width="50%" alignItems="center">
            {
              image ?
                <MDAvatar src={image} alt="profile-image" size="xl" shadow="sm" /> :
                <AltPerson />
            }
            <MDBox>
              <MDTypography variant="h5" fontWeight="medium" sx={{ ml: 2 }}>
                {name}
              </MDTypography>
              <MDTypography variant="h6" fontWeight="regular" sx={{ ml: 2 }}>
                {providerType}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" width="50%" alignItems="center" justifyContent="flex-end">
            <DisabledDisplay disabled={verify.disabled}>
              <MDButton variant="outlined" color="info" sx={{ mr: 1, mt: 1 }} disabled={verify.disabled} onClick={verify.handleClick}>Verify</MDButton>
            </DisabledDisplay>
            <DisabledDisplay disabled={decline.disabled}>
              <MDButton variant="outlined" color="error" sx={{ mr: 1, mt: 1 }} disabled={decline.disabled} onClick={decline.handleClick}>Decline</MDButton>
            </DisabledDisplay>
            <DisabledDisplay disabled={activate.disabled}>
              <MDButton variant="outlined" color="info" sx={{ mr: 1, mt: 1 }} disabled={activate.disabled} onClick={activate.handleClick} >Activate</MDButton>
            </DisabledDisplay>
            <DisabledDisplay disabled={deactivate.disabled}>
              <MDButton variant="outlined" color="error" sx={{ mr: 1, mt: 1 }} disabled={deactivate.disabled} onClick={deactivate.handleClick}>Deactivate</MDButton>
            </DisabledDisplay>
          </MDBox>
        </MDBox>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
ProviderHeader.defaultProps = {
  children: "",
};

// Typechecking props for the Header
ProviderHeader.propTypes = {
  children: PropTypes.node,
};

export default ProviderHeader;
