import { call, takeLatest } from "redux-saga/effects";
import { getCustomerOrdersAPI, deactivateCustomerAPI, activateCustomerAPI } from './customer.apis';
import { DEACTIVATE_CUSTOMER, GET_CUSTOMER_ORDERS, ACTIVATE_CUSTOMER } from './customer.constants';

function* getCustomerOrdersSaga({ payload, callback }) {
    try {
        const res = yield call(getCustomerOrdersAPI, payload);
        callback(res, null);
    } catch (err) {
        callback(null, err)
    }
}

function* deactivateCustomerSaga({ payload, callback }) {
    try {
        const res = yield call(deactivateCustomerAPI, payload);
        callback(res, null);
    } catch (err) {
        callback(null, err);
    }
}

function* activateCustomerSaga({ payload, callback }) {
    try {
        const res = yield call(activateCustomerAPI, payload);
        callback(res, null);
    } catch (err) {
        callback(null, err);
    }
}

export default function* customerProfileSagas() {
    yield takeLatest(GET_CUSTOMER_ORDERS, getCustomerOrdersSaga);
    yield takeLatest(DEACTIVATE_CUSTOMER, deactivateCustomerSaga);
    yield takeLatest(ACTIVATE_CUSTOMER, activateCustomerSaga);
}