import { call, takeLatest } from 'redux-saga/effects'
import { ADD_NEW_CATEGORY, DELETE_CATEGORY, EDIT_CATEGORY, GET_CATEGORIES_LIST, UPLOAD_IMAGE } from './category-management.constant'
import { addNewCategoryAPI, deleteCategoryAPI, editCategoryAPI, getCategoriesAPI, uploadImageAPI } from './category-management.apis'

function* getCategoriesListSaga({callback}) {
    try {
        const res = yield call(getCategoriesAPI)
        callback(res || [], null)
    } catch (e) {
        callback(null, e)
    }
}

function* uploadImageSaga({payload, callback}) {
    try {
        const res = yield call(uploadImageAPI, payload)
        callback(res, null)
    } catch (e) {
        callback(null, e)
    }
}

function* addNewCategorySaga({payload, callback}) {
    try {
        const res = yield call(addNewCategoryAPI, payload);
        callback(res, null)
    } catch(err) {
        callback(null, err);
    }
}

function* editCategorySaga({payload, callback}) {
    try {
        const res = yield call(editCategoryAPI, payload);
        callback(res, null)
    } catch(err) {
        callback(null, err);
    }
}

function* deleteCategorySaga({payload, callback}) {
    try {
        const res = yield call(deleteCategoryAPI, payload);
        callback(res, null);
    } catch(err) {
        callback(null, err);
    }
}

export default function* categoryManagementSagas() {
    yield takeLatest(GET_CATEGORIES_LIST, getCategoriesListSaga)
    yield takeLatest(UPLOAD_IMAGE, uploadImageSaga)
    yield takeLatest(ADD_NEW_CATEGORY, addNewCategorySaga)
    yield takeLatest(EDIT_CATEGORY, editCategorySaga)
    yield takeLatest(DELETE_CATEGORY, deleteCategorySaga)
}
