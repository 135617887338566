import { takeLatest, call } from "redux-saga/effects";
import { getCustomersAPI } from "./customers.apis";
import { GET_CUSTOMERS } from "./customers.constants";

function* getCustomersSaga({callback, payload}) {
    try {
        const result = yield call(getCustomersAPI, payload);
        callback(result, null);
    } catch (err) {
        callback(null, err);
    }
}

function* customerSagas() {
    yield takeLatest(GET_CUSTOMERS, getCustomersSaga)
}

export default customerSagas;