import { GET_CATEGORIES_LIST, ADD_NEW_CATEGORY, EDIT_CATEGORY, DELETE_CATEGORY, GET_SUB_CATEGORIES, UPLOAD_IMAGE } from './category-management.constant'

export const getCategoriesList = (callback) => ({ type: GET_CATEGORIES_LIST, callback })

export const addNewCategory = (payload, callback) => ({ type: ADD_NEW_CATEGORY, payload, callback })

export const editCategoryDetails = (payload, callback) => ({ type: EDIT_CATEGORY, payload, callback })

export const deleteCategory = (payload, callback) => ({ type: DELETE_CATEGORY, payload, callback })

export const getSubCategories = (payload) => ({ type: GET_SUB_CATEGORIES, payload })

export const uploadImage = (payload, callback) => ({ type: UPLOAD_IMAGE, payload, callback })