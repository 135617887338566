import { useEffect, useState } from 'react'

import DashboardLayout from "container-components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "container-components/Navbars/DashboardNavbar";
import Typography from "@mui/material/Typography";
import CategoryCard from './category-card';

import AddIcon from '@mui/icons-material/AddCircleOutlined';

import useStyles from './category-management.styles'
import AddServiceDialog from './category-dialog';
import { useDispatch } from 'react-redux';
import { deleteCategory, getCategoriesList } from './category-management.actions';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDDialog from 'components/MDDialog';
import { useNavigate } from 'react-router-dom';

export default function CategoryManagement() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [addDialog, setAddDialog] = useState(false);
    const [editCategoryValues, setEditCategoryValues] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [toDelete, setToDelete] = useState(null);

    useEffect(() => {
        dispatch(getCategoriesList((success, err) => {
            if (err) {
                console.log(err)
            } else {
                setCategoryList(success)
            }
        }))
    }, [rerender]);

    const handleEdit = ({ id, name, description, image, isPopular }) => {
        setEditCategoryValues({ id, name, description, isPopular, images: [image] })
        setAddDialog(true);
    }

    const handleClose = () => {
        setEditCategoryValues(null);
        setAddDialog(false);
    }

    const handleDelete = () => {
        dispatch(deleteCategory(toDelete.id, (success, err) => {
            if (err) {
                console.log(err);
            } else {
                setToDelete(null);
                setRerender(!rerender);
            }
        }))
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDDialog
                text={`Are you sure you want to delete this category ?`}
                title={'Delete Category'}
                onConfirm={handleDelete}
                confirmationText={'Delete'}
                open={!!toDelete}
                onClose={() => setToDelete(null)}
            />
            <MDBox>
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant='subtitle1' color='text.secondary'>
                        Tap on a category to view its associated sub categories.
                    </Typography>
                    <MDButton
                        variant="outlined"
                        startIcon={<AddIcon color="info" />}
                        onClick={() => setAddDialog(true)}
                        className={classes.addButton}
                    >
                        <Typography variant='content5' sx={{ color: '#1A73E8' }}>add new category</Typography>
                    </MDButton>
                </MDBox>
                <MDBox className={classes.categoriesContainer}>
                    {categoryList.map(ct => (
                        <CategoryCard
                            key={ct.id}
                            name={ct.name}
                            description={ct.description}
                            image={ct.image}
                            cardClick={() => {
                                localStorage.setItem('categoryId', ct.name);
                                navigate(`/categories/${ct.id}`)
                            }}
                            onEdit={() => handleEdit(ct)}
                            onDelete={() => setToDelete(ct)}
                        />
                    ))}
                </MDBox>
                <AddServiceDialog
                    open={addDialog}
                    onClose={handleClose}
                    defaultValues={editCategoryValues}
                    rerender={rerender}
                    setRerender={setRerender}
                />
            </MDBox>
        </DashboardLayout>
    );
}
