import MDTypography from "components/MDTypography";
import Chip from '@mui/material/Chip';

const RequestType = ({ title }) => (
  <Chip label={title} sx={{ml: 1}}/>
);

const humanTimestamp = (stringTimestamp) => {
  const timestamp = new Date(stringTimestamp);
  return `${timestamp.toLocaleTimeString()}, ${timestamp.toLocaleDateString()}`
}

const getRequestTypes = (newFirstName, newLastName, newBusinessType, newName) => {
  let requestTypes = [];
  if(newBusinessType) {
    requestTypes.push('Business Change')
  }

  if(newFirstName || newLastName || newName) {
    requestTypes.push('Name Change')
  }

  return requestTypes.map(typ => <RequestType title={typ} />)
}

export default function data(rowData) {
  return {
    columns: [
      { Header: "request Id", accessor: "requestId", align: "left" },
      { Header: "provider Id", accessor: "providerId", align: "left" },
      { Header: "requested At", accessor: "requestedAt" },
      { Header: "request Type", accessor: "requestType", width: "30%", align: "center" },
      { Header: "status", accessor: "status"},
    ],

    rows: rowData.map(({id, providerId, newFirstName, newLastName, newBusinessType, newName, createdAt, status}) => {
      return {
        id,
        requestId: <MDTypography component="p" variant="caption" color="text" fontWeight="medium"> {id} </MDTypography>,
        providerId: <MDTypography component="p" variant="caption" color="text" fontWeight="medium"> {providerId} </MDTypography>,
        requestedAt: <MDTypography component="p" variant="caption" color="text" fontWeight="medium"> {humanTimestamp(createdAt)} </MDTypography>,
        requestType: getRequestTypes(newFirstName, newLastName, newBusinessType, newName),
        status: <MDTypography component="p" variant="caption" color="text" fontWeight="medium"> {status && status.toUpperCase()} </MDTypography>,
      };
    })
  };
}
