import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Service({ id, quantity, name, noGutter }) {
  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
    >
      <MDBox lineHeight={1.125}>
        <MDTypography display="block" variant="caption" fontWeight="regular">
          {name}
        </MDTypography>
        <MDTypography variant="caption" fontWeight="regular" color="text">
          Service ID - {id}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" alignItems="center">
        <MDTypography variant="button" fontWeight="regular" color="text">
          x&nbsp;{quantity}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

Service.defaultProps = {
  noGutter: false,
};

Service.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  noGutter: PropTypes.bool,
};

export default Service;
