import { takeLatest, call } from "redux-saga/effects";
import { getServiceProvidersAPI } from "./serviceproviders.apis";
import { GET_SERVICE_PROVIDERS } from "./serviceproviders.constants";

function* getServiceProvidersSaga({callback, payload}) {
    try {
        const result = yield call(getServiceProvidersAPI, payload);
        callback(result, null);
    } catch (err) {
        callback(null, err);
    }
}

function* serviceProviderSagas() {
    yield takeLatest(GET_SERVICE_PROVIDERS, getServiceProvidersSaga)
}

export default serviceProviderSagas;