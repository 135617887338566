import { takeLatest, call } from "@redux-saga/core/effects"
import { getCustomerAPI, getOrderAPI, getProviderAPI, issueRefundAPI } from "./order.apis"
import { GET_CUSTOMER, GET_ORDER, GET_PROVIDER, ISSUE_REFUND } from "./order.constants"

function* getOrder({payload, callback}) {
    try {
        const result = yield call(getOrderAPI, payload)
        callback(result, null)
    } catch (err) {
        callback(null, err)
    }
}

function* getCustomer({payload, callback}) {
    try {
        const result = yield call(getCustomerAPI, payload)
        callback(result, null)
    } catch (err) {
        callback(null, err)
    }
}

function* getProvider({payload, callback}) {
    try {
        const result = yield call(getProviderAPI, payload)
        callback(result, null)
    } catch (err) {
        callback(null, err)
    }
}

function* issueRefund({payload, callback}) {
    try {
        const result = yield call(issueRefundAPI, payload);
        callback(result, null);
    } catch(err) {
        callback(null, err);
    }
}

function* getOrderSaga() {
    yield takeLatest(GET_ORDER, getOrder)
    yield takeLatest(GET_CUSTOMER, getCustomer)
    yield takeLatest(GET_PROVIDER, getProvider)
    yield takeLatest(ISSUE_REFUND, issueRefund)
}

export default getOrderSaga;