import { call, takeLatest } from 'redux-saga/effects'
import {GET_SUB_CATEGORIES, ADD_NEW_SUB_CATEGORY, DELETE_SUB_CATEGORY, EDIT_SUB_CATEGORY } from './sub-category-management.constant'
import { addNewSubCategoryAPI, deleteSubCategoryAPI, editSubCategoryAPI, getSubCategoriesAPI } from './sub-category-management.apis'

function* getSubCategoriesListSaga({payload, callback}) {
    try {
        const res = yield call(getSubCategoriesAPI, payload)
        callback(res || [], null)
    } catch (e) {
        callback(null, e)
    }
}

function* addNewSubCategorySaga({payload, callback}) {
    try {
        const res = yield call(addNewSubCategoryAPI, payload);
        callback(res, null)
    } catch(err) {
        callback(null, err);
    }
}

function* editSubCategorySaga({payload, callback}) {
    try {
        const res = yield call(editSubCategoryAPI, payload);
        callback(res, null)
    } catch(err) {
        callback(null, err);
    }
}

function* deleteSubCategorySaga({payload, callback}) {
    try {
        const res = yield call(deleteSubCategoryAPI, payload);
        callback(res, null);
    } catch(err) {
        callback(null, err);
    }
}

export default function* subCategoryManagementSagas() {
    yield takeLatest(GET_SUB_CATEGORIES, getSubCategoriesListSaga)
    yield takeLatest(ADD_NEW_SUB_CATEGORY, addNewSubCategorySaga)
    yield takeLatest(EDIT_SUB_CATEGORY, editSubCategorySaga)
    yield takeLatest(DELETE_SUB_CATEGORY, deleteSubCategorySaga)
}
