import { GET_CATEGORIES_SUCCESS } from './App.constants'

const initial = {
    categories: []
}

export default function reducer(state=initial, { type, payload }) {
    switch(type) {
        case GET_CATEGORIES_SUCCESS: return { ...state, categories: payload }
        default: return { ...state }
    }
}
