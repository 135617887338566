import React, { useEffect, useState } from 'react'
import { useTable, usePagination, useAsyncDebounce } from 'react-table'
import Spinner from 'components/Spinner';
import DataTableHeadCell from "container-components/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "container-components/Tables/DataTable/DataTableBodyCell";
import Filters from './filters';

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDPagination from "components/MDPagination";

import Icon from "@mui/material/Icon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Autocomplete from "@mui/material/Autocomplete";

export function CustomTable({
    filterOptions,
    columns,
    data,
    fetchData,
    totalCount,
    loading,
    isSorted,
    handleRowClick,
    noEndBorder,
    disableSearch,
    disablePagination,
    pageCount: controlledPageCount,
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        rows,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
            manualPagination: true,
            pageCount: controlledPageCount,
        },
        usePagination
    )

    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState({ category: '', status: 'all' });
    const [debounceSearch, setDebounceSearch] = useState('');
    const setEntriesPerPage = (value) => setPageSize(value);
    const onSearchChange = useAsyncDebounce(value => setDebounceSearch(value || undefined), 200);
    const setSortedValue = (column) => {
        let sortedValue;

        if (isSorted && column.isSorted) {
            sortedValue = column.isSortedDesc ? "desc" : "asce";
        } else if (isSorted) {
            sortedValue = "none";
        } else {
            sortedValue = false;
        }

        return sortedValue;
    };

    const handleFilters = (newFilters) => {
        if (JSON.stringify(filters) !== JSON.stringify(newFilters)) {
            setFilters(newFilters);
        }
    }

    useEffect(() => {
        fetchData({ pageIndex, pageSize, filters, search: debounceSearch })
    }, [pageIndex, pageSize, debounceSearch, filters])

    return (
        <TableContainer sx={{ boxShadow: "none" }}>
            {
                disablePagination ? null :
                    <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                        <MDBox display="flex" alignItems="center">
                            <Autocomplete
                                disableClearable
                                value={pageSize.toString()}
                                options={["10", "20", "50"]}
                                onChange={(_, newValue) => {
                                    setEntriesPerPage(parseInt(newValue, 10));
                                }}
                                size="small"
                                sx={{ width: "4rem" }}
                                renderInput={(params) => <MDInput {...params} />}
                            />
                            <MDTypography variant="button" color="secondary" fontWeight="regular">
                                &nbsp;&nbsp;
                                {
                                    `rows per page of total ${totalCount || 0} rows`
                                }
                            </MDTypography>
                        </MDBox>
                        <MDBox display="flex" justifyContent="space-between" alignItems="center">
                            {
                                disableSearch ? null :
                                    <MDInput
                                        placeholder="Search..."
                                        value={search}
                                        size="small"
                                        sx={{ width: "12rem" }}
                                        fullWidth
                                        onChange={({ currentTarget }) => {
                                            setSearch(currentTarget.value);
                                            onSearchChange(currentTarget.value);
                                        }}
                                    />
                            }
                            {
                                filterOptions && filterOptions.length ? <Filters filterOptions={filterOptions} handleFilters={handleFilters} /> : null
                            }
                        </MDBox>
                        <MDBox display="flex" alignItems="center">
                            <MDTypography variant="button" color="secondary" fontWeight="regular">
                                Page {pageOptions.length ? pageIndex + 1 : 0} of {pageOptions.length}
                            </MDTypography>
                            &nbsp;
                            <MDPagination item onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                <Icon sx={{ fontWeight: "bold", transform: 'rotate(180deg)' }}>double_arrow</Icon>
                            </MDPagination>
                            <MDPagination item onClick={() => previousPage()} disabled={!canPreviousPage}>
                                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                            </MDPagination>
                            <MDPagination item onClick={() => nextPage()} disabled={!canNextPage}>
                                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                            </MDPagination>
                            <MDPagination item onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                <Icon sx={{ fontWeight: "bold" }}>double_arrow</Icon>
                            </MDPagination>
                        </MDBox>
                    </MDBox>
            }
            <Table {...getTableProps()}>
                <MDBox component="thead">
                    {headerGroups.map(headerGroup => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <DataTableHeadCell
                                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                                    width={column.width ? column.width : "auto"}
                                    align={column.align ? column.align : "left"}
                                    sorted={setSortedValue(column)}
                                >
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </DataTableHeadCell>
                            ))}
                        </TableRow>
                    ))}
                </MDBox>
                <TableBody {...getTableBodyProps()}>
                    {
                        loading ?
                            <TableRow>
                                <Spinner active={loading} />
                            </TableRow> :
                            page.map((row, key) => {
                                prepareRow(row)
                                return (
                                    <TableRow {...row.getRowProps()} onClick={() => handleRowClick(row.original?.id)}>
                                        {row.cells.map((cell) => (
                                            <DataTableBodyCell
                                                noBorder={noEndBorder && rows.length - 1 === key}
                                                align={cell.column.align ? cell.column.align : "left"}
                                                {...cell.getCellProps()}
                                            >
                                                {cell.render("Cell")}
                                            </DataTableBodyCell>
                                        ))}
                                    </TableRow>
                                );
                            })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
