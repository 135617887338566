import React from 'react';
import PropTypes from 'prop-types';
import LocationIcon from '@mui/icons-material/LocationOn';
import MDTypography from 'components/MDTypography';

const K_WIDTH = 100;
const K_HEIGHT = 100;
const styles = {
    position: 'absolute',
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
}

const Marker = ({ name, address, zoom, onClick }) => {
    const sizeFactor = `${zoom*1.5}px`;
    return (
        <div style={{ ...styles }} onClick={onClick}>
            <LocationIcon fontSize="large" style={{ color: '#d01e1e' }} />
            <MDTypography variant='p' fontSize={sizeFactor} textTransform='capitalize'>{name}</MDTypography>
            <MDTypography variant='p' fontSize='16px' textTransform='capitalize'>{address}</MDTypography>
        </div>)
}

Marker.defaultProps = {
    onClick: null,
};

Marker.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
};

export default Marker;